/* PADDING */

.padding-5 {
  padding: 5px;
}

.padding-20 {
  padding: 20px;
}

.padding-30 {
  padding: 30px;
}

.padding-40 {
  padding: 40px;
}

.padding-50 {
  padding: 50px;
}

.padding-65 {
  padding: 65px;
}

.padding-h-20 {
  padding: 0 20px;
}

.padding-h-50 {
  padding: 0 50px;
}

.padding-v-50 {
  padding: 50px 0;
}

.padding-v-70 {
  padding: 70px 0;
}

.padding-v-100 {
  padding: 100px 0;
}

.padding-10-15 {
  padding: 10px 15px;
}

.padding-10-30 {
  padding: 10px 30px;
}

.padding-20-30 {
  padding: 20px 30px;
}

.padding-30-50 {
  padding: 30px 50px;
}

.padding-50-30 {
  padding: 50px 30px;
}

.padding-30-80 {
  padding-top: 30px;
  padding-bottom: 80px;
}
.padding-bt-80 {
  padding-bottom: 80px;
}

.remove-padding {
  padding: 0 !important;
}

.padding-left-20 {
  padding-left: 20px;
}
.padding-right-30 {
  padding-right: 30px;
}

/*****************************/


/* MARGIN */

.ma-5{
  margin: 5px;
}

.ma-10{
  margin: 10px;
}

.ma-15{
  margin: 15px;
}

.margin-5 {
  margin: 5px 0;
}

.margin-10 {
  margin: 10px 0;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}
.mb-70 {
  margin-bottom: 70px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-200 {
  margin-top: 200px;
}

.mr-70 {
  margin-right: 70px;
}

.mv-3 {
  margin-top: 3px;
  margin-bottom: 3px;
}

.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mv-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mv-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mh-10 {
  margin-right: 10px;
  margin-left: 10px;
}

.mh-30 {
  margin-right: 30px;
  margin-left: 30px;
}

.mh-40 {
  margin-right: 40px;
  margin-left: 40px;
}

.mh-50 {
  margin-right: 50px;
  margin-left: 50px;
}