/* FAQ / INSTRUCTIONS */

.instructions-container {
  max-width: 800px;
  margin: auto;
  padding: 0 30px;
}

.instructions-step {
  border: 1px solid $grey;
}

.faq, .instructions {
  main.bgc-yellow, main.bgc-blue {
    padding: 150px 0;
    box-sizing: border-box;
    &.faq-theme {
      padding: 30px 0;
    }
  }
}

.faq-illus {
  max-width: 360px;
  max-height: 240px;
}

.faq-btn {
  max-width: 400px;
  display: block;
  margin: 30px auto 0;
  padding: 40px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  border-radius: 30px;
  background-color: white;
}

.faq-picto {
  width: 50px;
  margin-right: 50px;
}

