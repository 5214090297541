/* PROVIDER PAGE */

.provider-menu {
  font-size: 20px;
  font-weight: 100;
  background-color: white;
  padding: 15px 0;
  z-index: 20;

  &.uk-sticky.uk-active{
    width: 100%!important;
    top: 70px!important;
    left: 0!important;
  }
  a {
    font-size: 20px;
    font-weight: 400;
  }
    a:hover, .link-active {
    color: $blue;
    padding-bottom: 1px;
    border-bottom: 1px solid $blue;
  }

  @media screen and(max-width: 992px){
    display: flex!important;
    overflow-x: scroll;
    position: initial!important;
    width: 100vw!important;
    transform: translateX(-25px);
    border-radius: 0!important;
    a{
      font-size: 16px;
    }
  }
}

.provider-rating {
  span {
    height: 50px;
    width: 50px;
  }
}

.provider-bottom-mobile{
  display: none;

  @media screen and(max-width: 992px){
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: white;
    padding: 10px 5px;
    width: 100%;
    z-index: 999;
    box-shadow: 1px 1px 15px 0 black;

    button{
      width: 50%;
      background-color: $yellow;
    }
  }
}

.input-rating--full {
    background-color: $yellow;
  }

.js-btn-modal:hover{
  cursor: pointer;
}

.provider-pictures {
  margin: 20px -15px 30px;
}

.provider-picture-1 {
  border-radius: 20px 0 0 20px;
  height: 460px;
  width: 555px;
}

.provider-picture-2 {
  height: 222px;
}
.provider-picture-3 {
  border-radius: 0 20px 0 0;
  height: 222px;
}
.provider-picture-4 {
  height: 222px;
}

.provider-picture-5 {
  border-radius: 0 0 20px 0;
  height: 222px;
}

.provider-pictures-extend {
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 15px;
  background-color: $grey;
  position: absolute;
  left: 35px;
  bottom: 20px;
  font-size: 16px;
}

.provider-aside {
  margin-top: 90px;
}

.card-aside {
  padding: 20px 20px 30px;
  /* border: 2px solid $blue;  BORDER STYLE IN FRONT*/
  border-radius: 15px;

  .top-mobile{
    display: none;

    @media screen and(max-width: 992px){
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid black;
      padding-bottom: 10px;
      margin-bottom: 25px;

      .topName{
        font-weight: bold;
      }

      .topCross{
        font-size: 20px;
      }

    }
  }
}

.provider section:not(.section-other-providers) {
    scroll-margin: 80px;
    padding-top: 40px;
}

#description, #services, #unincludedServices, #infos {
    padding-right: 30px;
    ol li {
        margin-left: 15px;
        list-style: decimal !important;
    }
    ul li {
        margin-left: 17px;
        list-style: disc !important;
    }
}

.provider-like {
  margin-right: 10px;
  svg {
    width: 50px;
    height: 50px;
  }
  &.like {
    svg g > * {
      fill: $red;
    }
  }
}

#prestations{
  padding-top: 0;

  .product {
    padding: 20px 0;
  
    .description{
      a{
        color:blue;
        text-decoration: underline;
      }
      ul{
        padding-left: 15px;
        li{
          text-align: left;
        }
      }
    }
  }
} 

.provider-tcheen {
  font-size: 20px;
  /* color: $blue; COLOR IN FRONT*/
  font-weight: 300;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
  svg {
    position: absolute;
    top: -31px;
    right: -33px;
    width: 45px;
  }
}


.card-provider {
  padding: 20px 20px 20px 20px;
}

.card-provider-pictures {
  height: 210px;
}

.favourite-heart {
  top: 30px;
  right: 35px;
  z-index: 10;
}



// Infos
.provider-profile-h2{
  display: flex;
  align-items: center;
}

.provider-profile-infos-img-box{
  position: relative;
  margin-left: 5px;
  img{
    width: 20px;
    height: 20px;
  }

  .text-box{
    display: none;
    position: absolute;
    top: 50%;
    left: 40px;
    width: 350px;
    transform: translateY(-50%);
    text-align: center;
    color: $blue;
    background-color: $alt2Grey;
    padding: 5px;
    border: 1px solid $alt2Grey;
    border-radius: 10px;

    @media screen and (max-width: 500px) {
      top: 100%;
      left: -120px;
      width: 200px;
      transform: none;

    }

  }
}

.provider-profile-infos-img-box:hover{
  .text-box{
    display: block;
  }
}




// Reviews

.review-rate {
  width: 65px;
  margin-right: 20px;
  margin-top: 5px;
}

.review-user {
  width: 35%;
  margin-right: 30px;
}

.review-comment {
  margin-top: 10px;
  width: 100%;
}

.review-response {
  margin: 15px 0 0 180px;
  padding: 15px;
  background-color: rgba(242, 249, 251, 0.94);
  border-radius: 10px;
}

.provider-suggestions {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 30px;
    margin-right: 10px;
    margin-top: -10px;
  }
}

.provider-quote-sum {
  border: 2px solid $grey;
  border-radius: 16px;
  margin-bottom: 16px;

  @media screen and(max-width: 992px){
    width: 100%!important;
  }
  label {
    font-weight: 500;
    display: block;
    text-transform: uppercase;
  }
}

.provider-quote-sum-item {
  padding: 10px 15px;
  input {
    border: none;
    width: 100%;
  }
}

#js-provider-quote-place {
  border-bottom: 2px solid $grey;
}

#js-provider-quote-date {
  border-right: 2px solid $grey;
}

#js-provider-quote-hour {
  border-top: 2px solid $grey;
}

.provider .ui-datepicker {
  border: 2px solid $grey !important;
}

.provider-suggestion-card {
  .uk-slideshow ul {
    height: 210px;
  }
  .uk-slidenav svg {
    width: 13px;
    fill: $white;
  }
}

.slider-other-providers {
  margin: 30px auto 0;
  .card {
    height: 350px;
  }
}

.section-other-providers {
  background-color: $lightGrey;
  padding: 70px 0;
  margin-top: 100px;
}

/*****************************/



/* PRODUCTS */

.js-tag-filter li {
  &.active {
    border: 2px solid #e5db3f;
  }
}

.js-group-tag {
  display: none;
}

.uk-radio:checked {
  background-image: none !important;
  background-color: $blue !important;
}

[type="checkbox"]:checked{
  -webkit-appearance: none;
  outline: none;
  border: 1px solid black;
  border-radius: 0.2em;
  width: 13px !important;
  height: 13px !important;
  background-color: #1EA7AC;
}

.provider-number-select-choice {
  border: 2px solid $grey;
  border-radius: 15px;
  width: 45px;
  height: 30px !important;
  text-align: center;
}


.button-select-product {
  color: white;
  background-color: #d8d8d8;
  border-radius: 50%;
  padding: 4px;
  border: none;
  width: 25px;
  outline: none !important;
}

.button-select-product:hover {
  cursor: pointer;
  background-color: white;
  color: black;
}

.button-select-product:active {
  outline: none !important;
  border: none !important;
}

.provider-cancelPolicy-information {
  border: 2px solid $grey;
  border-radius: 15px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
}


/**********************************************/


/* DATEPICKER - jQuery-ui */

.provider .ui-datepicker {
  border: none !important;
  border-radius: 15px;
  width: 100% !important;
  .ui-datepicker-inline{
    width: 100%;
  }
  .ui-state-default {
    background-color: inherit;
    border: none;
    text-align: center;
    font-size: 16px;
  }
  .ui-state-active {
    border: none;
    border-radius: 20px;
    color: $white;
    background-color: $yellow;
  }
  .ui-datepicker-calendar {
    width: 90%;
  }
  .ui-widget-header {
    background-color: inherit;
    border: none;
  }
  .ui-datepicker-month {
    font-weight: bold !important;
  }
  .ui-datepicker-year {
    font-weight: bold !important;
  }
.ui-datepicker .ui-datepicker-next {
    right: 20%;
  }
  .ui-datepicker .ui-datepicker-prev {
    left: 20%;
  }

.ui-datepicker-prev span, .ui-datepicker-next span {
    width: 30px;
    height: 30px;
    margin: 10px;
    background-image: none !important;
}

.ui-datepicker-prev {
    margin-right: 20px;
    content: url("../images/arrow/arrow-prev.png")
}

.ui-datepicker-next {
    margin-left: 20px;
    content: url("../images/arrow/arrow-next.png");
}
}

/**********************************************/


/* MODAL */

.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #cccccc;
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
    }
    .modal-content {
    border-radius: 16px;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    max-height: 90%;
    overflow-y: auto;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 1rem 1.5rem;
    width: 600px;
    @media screen and (max-width: 640px){
      width: 95%;
    }

      .top{
        border-bottom: 1px solid $grey;
        padding-bottom: 20px;
        margin-bottom: 20px;
      }

      h4, .selectEvent, .describeDemand{
        text-align: center;
        font-weight: 600;
        justify-content: center;
      }
    }

    #buttonEvent,#buttonEvent2{
      button{
        padding: 0;
        background: $yellow;
        color: black !important;
      }
    }

    .buttonSend{
      text-align: center;
      justify-content: center;

      button{
        background: $yellow !important;
        font-weight: 600;
        font-size: 18px;
        color: black !important;
        padding: 10px 20px !important;
        text-transform: uppercase;
      }
    }

    .close-button {
      float: right;
      position: absolute;
      right: 30px;
      width: 2rem;
      height: 2rem;
      font-size: 30px;
      line-height: 1.5rem;
      font-weight: 200;
      text-align: center;
      cursor: pointer;
      border-radius: 30px;
      color: #999;
    }
    .show-modal {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
    visibility: visible;
    z-index: 10000;
    transform: scale(1.0);
    transition: opacity 0.15s linear;
    }

    /**********************************************/

/* SCROLLBAR FOR MAC OS*/

.force-show-scrollbars ::-webkit-scrollbar-track:vertical {
    border-left: 1px solid #E7E7E7;
    box-shadow: 1px 0 1px 0 #F6F6F6 inset, -1px 0 1px 0 #F6F6F6 inset;
}

.force-show-scrollbars ::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: #FAFAFA;
    width: 16px;
}

.force-show-scrollbars ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: #C1C1C1;
    border-color: transparent;
    border-radius: 9px 8px 8px 9px;
    border-style: solid;
    border-width: 3px 3px 3px 4px; /* Workaround because margins aren't supported */
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* Unfortunately scrollbars can't use CSS transitions. Also, it's not possible
   to highlight the thumb when the scrollbar track is hovered without some
   JavaScript acrobatics; https://jsfiddle.net/QcqBM/6/ is a start, but you
   also have to check whether the element has a scrollbar and if so how wide
   it is. */
.force-show-scrollbars ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

.point-box{
  min-width: 100px;
}


#frais{
  ul{
    li{
      list-style-type: initial;
    }
  }
}




// NEW SCSS DE MANU

.titleBlock{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 7px;

  h1{
    padding-right: 15px;
  }
}

.tags{
  // margin-bottom: 10px;
  font-weight: 500;

  span{
    display: inline-block;
    padding: 4px 8px;
    margin-right: 4px;
    border-radius: 8px;
    margin-bottom: 5px;
    font-size: 14px;

    &.bgpink{
        background: $pink;
    }

    &.bggreen{
        background: $green;
    }

    &.bgyellow{
      background: $yellow;
    }

    &.bgpurple{
      background: $purple;
      color: white;
    }
  }
}

.informations{
  margin-top: 50px;
}

section.tags{
  span{
    margin-bottom: 10px;
  }
}

.modalCarousel{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  z-index: 9999;
  padding: 50px 100px;
  opacity: 0;
  pointer-events: none;
  transition: 1s ease;
  max-width: 100%;

  @media screen and (max-width: 640px) {
    padding: 50px 10px;
  }

  &.active{
    opacity: 1;
    pointer-events: inherit;
    transition: 1s ease;
  }

  .closeModal{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;

    opacity: .7;
    transition: .3s ease;

    &:hover{
      opacity: 1;
      transition: .3s ease;
    }

    span{
      width: 30px;
      height: 3px;
      background: white;
      display: block;
      position: absolute;

      &:first-child{
        transform: rotate(45deg);
      }

      &:last-child{
        transform: rotate(-45deg);
      }
    }
  }

  .btnCarousel{
    display: flex;
    align-items: center;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 45px;
    cursor: pointer;
    opacity: .7;
    transition: .3s ease;
    z-index: 9999;


    &:hover{
      opacity: 1;
      transition: .3s ease;
    }
    
    span{
      width: 30px;
      height: 3px;
      background: white;
      display: block;
      position: absolute;

      &:first-child{
        transform: rotate(45deg);
      }

      &:last-child{
        transform: rotate(-45deg);
      }
    }

    &.btnNext{
      justify-content: flex-end;
      right: 30px;

      
      span{
        &:first-child{
          top: 10px;
        }
  
        &:last-child{
          top: 30px;
        }
      }
      
    }

    &.btnPrev{
      justify-content: flex-start;
      left: 30px;

      span{
        &:first-child{
          top: 30px;
        }
  
        &:last-child{
          top: 10px;
        }
      }
    }

  }

  .owl-carousel-gallery{
    width: 100%;
    height: 100%;

    .owl-stage-outer, .owl-stage, .owl-item{
      height: 100%;
    }
  }

  .bgImg{
    height: 100%;
    .text-box{
      text-align: center;
      p{
        color: white;
      }
    }

    img{
      width: 100%;
      height: 90%;
      object-fit: contain;
    }
  }
}

.back-mobile{
  display: none;

  @media screen and(max-width: 992px){
    display: block;
    width: 40px;
    height: 40px;
    background-color: $grey3;
    color: white;
    position: absolute;
    top: 65px;
    left: 20px;
    z-index: 9;
    font-size: 30px;
    border-radius: 50%;
    background-image: url("../images/arrow/prev.svg");
    background-size: 30%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.nb-picture-mobile{
  display: none;
  @media screen and(max-width: 992px){
    display: block;
    position: absolute;
    top: 250px;
    right: 20px;
    z-index: 1;
    background-color: $black2;
    opacity: 0.8;
    color: white;
    padding: 10px;
    border-radius: 10px;
  }

}



.owl-carousel-gallery-mobile{
  width: 100%;
  height: 270px;
  position: relative;



  .owl-stage-outer, .owl-stage, .owl-item{
    height: 100%;
  }

  .bgImg{
    height: 100%;
  
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }


  }
}

.mainCont{
  margin-top: 60px;

  @media screen and (max-width: 640px) {
    margin-top: 20px;
  }
}



.underTitle{
  address, .rating{
    display: inline-block;

    .icon-responsive{
      display: none;
    }
  }

  .postcode{
    display: none;
  }

  @media screen and(max-width: 992px){
    address.uk-margin-remove{
      margin-bottom: 0!important
    }
    .postcode{
      display: inline-block;
    }
    .rating{
      display: block;
      .note{
        text-decoration: underline!important;
      }

      .icon-responsive{
        display: inline-block;
        width: 25px;
        height: 25px;
        background-image: url("../images/stars/star-solid.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        vertical-align: middle;
        margin-right: 5px;
      }

      .target{
        display: block;
        font-style: italic;
        font-weight: 300;
      }
    }
  }
}

#menu{
  background: $lightGrey;
  display: inline-block;
  padding: 15px 20px;
  border-radius: 10px;
  z-index: 1000;

  li{
    display: inline-block;
  }
}


.photosGallery{
  display: flex;
  gap: 14px;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  margin-top: 30px;
  position: relative;
  margin-bottom: 30px;

  .big, .small{
    width: calc(50% - 7px);
    height: 450px;
    position: relative;
  }

  .openCarousel{
    position: absolute;
    bottom: 14px;
    right: 15px;
    background: #f5f5f5;
    padding: 7px 15px 6px 8px;
    border-radius: 10px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: .3px;
    cursor: pointer;
    opacity: 1;
    transition: .3s ease;

    &:hover{
      opacity: 0.9;
      transition: .3s ease;
    }

    img{
      padding-right: 3px;
      display: inline-block;
    }
  }

  .small{
    display: flex;
    flex-wrap: wrap;
    gap: 14px;

    .bgImg{
      width: calc(50% - 7px);
      height: 215px;
      position: relative;
    }
  }

  .bgImg{
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;

    img{
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.descriptionLieu{
  p{
    margin-bottom: 10px;
  }
  li{
    list-style-type: initial;
  }
}

.pictoHeader{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid $grey;

  .pictoItem{
    width: 50%;
    min-width: 50%;
    margin-bottom: 10px;

    .pictoImg{
      width: 20px;
      display: inline-block;
      position: relative;

      .infoBulle{
        display: none;
        z-index: 10;
        position: absolute;
        top: 30px;
        left: 0px;
        background-color: white;
        padding: 20px 15px;
        border-radius: 15px;
        box-shadow: 0 1px 4px 0 rgba(0,0,0,0.75);
        p{
          font-style: italic;
          font-size: 14px;
        }

      }

      &:hover{
        .infoBulle{
          display: block;
        }
      }

    }
  }

}

.provider-aside{

  .links{
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid $grey;

    .link{
      padding-bottom: 10px;
      display: flex;
      align-items: flex-start;
      cursor: pointer;

      img{
        display: inline-block;
        padding-right: 10px;
        padding-top: 3px;
      }

      span{
        display: inline-block;
        text-decoration: underline;
      }
    }
  }

  .uk-text-bold{
    font-weight: 600!important;
  }
}

.descriptionContainer{
  border-bottom: 1px solid $grey;
  margin-bottom: 25px;
  padding-bottom: 25px;

  .descriptionLieu{
    overflow: hidden;
    transition: .3s ease;
    margin-bottom: 10px;
  }

  .readMore{
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
    display: none;

    &.active{
      display: inline-block;
    }
  }
}

.tcheenwordContainer{
  .readMore{
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
    display: none;

    &.active{
      display: inline-block;
    }
  }
}


.envEngagement{
  margin-bottom: 30px;
  padding-bottom: 20px;

  .title{
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
}

.engList{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  .engTitle{
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 20px;
  }

  .engItem{
    width: 50%;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;

    @media screen and (max-width: 640px) {
      width: 100%;
    }

    img{
      display: inline-block;
      width: 25px;
      margin-right: 10px;

    }

    span{
      display: inline-block;
    }

    &.inactive{
      text-decoration: line-through;
    }
  }
}  

.readMore-engagement{
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  margin-top: 20px;
  color: $green;
  text-align: right;
  cursor: pointer;

  display: none;

  &.active{
    display: block;
  }
}


.modalMore{
  position: fixed;
  top: 0;
  left:0;
  right:0;
  bottom: 0;
  background: rgba(0,0,0,0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  opacity: 0;
  pointer-events: none;
  transition: .3s ease;

  &.volet{
    justify-content: flex-end;

    .inner{
      max-height: inherit;
      border-radius: 10px 0 0 10px;
      max-width: 420px;
      width: inherit;
      height: 100%;
      font-size: 16px;
      padding: 25px 20px !important;
    }
  }

  &.open{
    opacity: 1;
    pointer-events: inherit;
    transition: .3s ease;
  }

  .inner{
    background: white;
    padding: 40px 30px 40px 50px;
    border-radius: 10px;
    max-height: calc(100vh - 100px);
    width: 90%;
    max-width: 800px;
    position: relative;
    display: flex;
    flex-direction: column;

    .closeModal{
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }

    .top{
      border-bottom: 1px solid $grey;
      padding-bottom: 20px;
      margin-bottom: 20px;
      margin-right: 20px;

      .modalTitle{
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;

        @media screen and(max-width: 992px){
          max-width: 80%;
        }
      }
    }

    .modalMore-content{
      max-height: initial!important;
      overflow: scroll;
      padding-right: 20px;

      p{
        margin-bottom: 10px;
      }

      .contentTop{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $grey;
        padding-bottom: 20px;
        margin-bottom: 20px;

        @media screen and(max-width: 992px){
          flex-direction: column;
        }

        .contentTop-title{
          font-weight: 18px;
          font-weight: 600;
          line-height: 24px;
          text-transform: uppercase;
        }

        .cancelingCondition{
          font-size: 14px;
          line-height: 21px;
          font-style: italic;
          margin-bottom: 5px;
          position: relative;

          .info{
            display: none;
            position: absolute;
            top: 25px;
            left: 5px;
            background-color: white;
            padding: 20px 15px;
            border-radius: 15px;
            box-shadow: 0 1px 4px 0 rgba(0,0,0,0.75);
            z-index: 10;
            p{
              font-style: italic;
              font-size: 14px;
            }
          }

          &:hover{
            .info{
              display: block;
            }
          }
        }
      }

      .contentDescription{
        margin-bottom: 30px;

        .short{
          li{
            list-style-type: initial;
            margin-left: 20px;
          }
          .showMore{
            font-weight: bold;
            text-decoration: underline;
            margin-top: 5px;
          }
        }

        .integral{
          display: none;
          li{
            list-style-type: initial;
            margin-left: 20px;
          }
        }

        .full{
          li{
            list-style-type: initial;
            margin-left: 20px;
          }
        }

      }

      .contentTitle{
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid $grey;

      }

      .formules{
        .price{
          font-size: 14px;

          .bold{
            font-weight: bold;
          }
        }

        .choices{
          border: none;
        }

        .trash-box{
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          span{
            display: inline-block;
            width: 20px;
            height: 20px;
            background-image: url("../images/trash.svg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;

            &:hover{
              cursor: pointer;
            }
          }
        }
      }

      .button{
        width: 100%;
        border-radius: 8px;
        padding: 8px 5px;
        font-weight: 600;
        border: 2px solid $yellow;
        text-align: center;

        &.bgyellow{
          background-color: $yellow;
        }
      }

      .infoBulle{
        background: $lightGrey;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        padding: 15px;
        gap: 20px;
        margin-bottom: 20px;

        .number{
          width: 40px;
          min-width: 40px;
          height: 40px;
          border-radius: 40px;
          background: white;
          border: 2px solid $yellow;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
        }

        .infoBulle-picto{
          width: 40px;
          min-width: 40px;

          img{
            width: 100%;
          }
        }

        .infoBulle-content{
          
          .infoBulle-title{
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 5px;
          }
        }
      }

      .linkWithText{
        border: 1px solid $grey;
        border-radius: 8px;
        padding: 15px;

        .button{
          margin-top: 20px;
          text-transform: uppercase;
        }
      }
    }
  }

  &.modalMore-contactPresta, &.modalMore-garantie{
    
    div.inner{
      padding: 40px 50px 80px 50px;

      @media screen and(max-width: 992px){
        padding-bottom: 100px!important;
      }
    }
    
    .bottom{
      padding-top: 20px;
      margin-top: 20px;
      position: absolute;
      bottom: 20px;
      width: calc(100% - 80px);
      text-align: center;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      background-color: white;



      .link{
        padding: 0 10px;

        a{
          text-decoration: underline !important;
          justify-content: center;
        }
      }
    }


  }
}

.cardModal{

  .modalMore-content{
    padding-right: 15px;
  }



  .bottom{
    text-align: right;
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-decoration-color: $yellow;
    padding-top: 10px;
  }
}

.tcheenword{
  margin-bottom: 20px;
  margin-top: 10px;
  li{
    list-style-type: initial;
  }
}

.rubrikTitle{
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid $grey;

  span{
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
  }
}

#prestations{

  .cards{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .cardItem{
      width: calc(49% - 10px);
      // margin-bottom: 20px;
      border-radius: 15px;
      -webkit-border-radius: 15px;
      box-shadow: 0 1px 4px rgba(#1a1a1a, .25);
      padding-bottom: 15px;
      overflow: hidden;
      cursor: pointer;

      @media screen and (max-width: 640px) {
        width: 100%;
      }

      &>*{
        padding: 15px;
      }


      .cardItem-img{
        padding: 0;
        position: relative;

        .btn-openCarousel{
          position: absolute;
          top: 15px;
          left: 15px;
          background: #f5f5f5;
          padding: 7px 15px 6px 8px;
          border-radius: 10px;
          font-size: 14px;
          text-transform: uppercase;
          letter-spacing: .3px;
          cursor: pointer;
          opacity: 1;
          transition: .3s ease;

        }
        .img-box{
          height: 230px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            width: 100%;
            height: auto;
          }
        }

        .modal-pictures{
          z-index: 9999999;
          display: none;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100vh;
          background-color: rgba(0,0,0,0.8);
          opacity: 0;
          transition: 0.3s ease;

          &.open{
            display: block;
            opacity: 1;
          }

          .content{
            padding: 50px 100px;
            width: 80%;
            overflow: hidden;
            position: relative;
            margin: 0 auto;
            height: 100%;

            .closeModal{
              display: flex;
              justify-content: center;
              align-items: center;
              position: fixed;
              top: 20px;
              right: 20px;
              width: 40px;
              height: 40px;
              z-index: 10;
              opacity: .7;
              transition: .3s ease;

              &:hover{
                opacity: 1;
                transition: .3s ease;
              }

              span{
                width: 30px;
                height: 3px;
                background: white;
                display: block;
                position: absolute;

                &:first-child{
                  transform: rotate(45deg);
                }

                &:last-child{
                  transform: rotate(-45deg);
                }
              }
            }

            .btnCarousel{
              display: flex;
              align-items: center;
              position: fixed;
              top: 50%;
              transform: translateY(-50%);
              width: 40px;
              height: 45px;
              cursor: pointer;
              opacity: .7;
              transition: .3s ease;
              z-index: 9999;


              &:hover{
                opacity: 1;
                transition: .3s ease;
              }

              span{
                width: 30px;
                height: 3px;
                background: white;
                display: block;
                position: absolute;

                &:first-child{
                  transform: rotate(45deg);
                }

                &:last-child{
                  transform: rotate(-45deg);
                }
              }

              &.btnNext{
                justify-content: flex-end;
                right: 30px;


                span{
                  &:first-child{
                    top: 10px;
                  }

                  &:last-child{
                    top: 30px;
                  }
                }

              }

              &.btnPrev{
                justify-content: flex-start;
                left: 30px;

                span{
                  &:first-child{
                    top: 30px;
                  }

                  &:last-child{
                    top: 10px;
                  }
                }
              }

            }

            .owl-carousel, .owl-carousel *{
              height: 100%;
            }

            .bgImg{
              height: 100%;
              img{
                height: 100%;
                width: 100%;
                object-fit: contain;
              }
            }

          }
        }
      }

      .cardItem-title{
        padding-top: 30px;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        text-overflow: ellipsis;
        margin-bottom: 10px;
        white-space: nowrap;
        overflow: hidden;
      }

      .cardItem-pictos{
        border-bottom: 1px solid $grey;
        margin-bottom: 10px;
        padding-bottom: 10px;

        &:nth-child(1), &:nth-child(2){
          min-width: 50%;
        }
      }
      
      .pictos{
        display: flex;
        flex-wrap: wrap;
        min-height: 80px;


        .picto{
          margin-bottom: 5px;
          padding-right: 5px;
          box-sizing: border-box;
          position: relative;

          img{
            display: inline-block;
            padding-right: 3px;
          }

          .picto-label{
            width: max-content;
            display: none;
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 10;
            background-color: white;
            padding: 10px;
            border-radius: 10px;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.75);

            @media screen and(max-width: 992px){
              display: inline;
              position: static;
              background-color: transparent;
              padding: 0;
              box-shadow: initial;
              border-radius: 0;
            }
          }

          &:hover{
            .picto-label{
              display: inline-block;
            }
          }
        }
      }

      .cardItem-price{
        padding: 20px 15px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        min-height: 130px;

        .showPrice{
          margin-top: 15px!important;
          font-weight: bold;
          text-align: right;
          span{
            border-bottom: 2px solid $yellow;
          }
        }
      }
    }

    .lastCardItem{
      width: calc(50% - 10px);
      // margin-bottom: 20px;
      border-radius: 15px;
      box-shadow: 0 1px 4px rgba(#1a1a1a, .25);
      padding: 15px;
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media screen and (max-width: 640px) {
        width: 100%;
      }
    
      .topImg{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 20px 0 10px;
      }

      .lastCard-txt{
        text-align: center;

      }

      .contactButton{
        border: 2px solid $yellow;
        border-radius: 8px;
        padding: 10px 20px;
        width: 100%;
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        transition: .3s ease;
        text-align: center;
        cursor: pointer;

        &:hover{
          background: $yellow;
          transition: .3s ease;
        }
      }
    
    }
  }
}

#devis{
  margin-top: 140px;

  .card-aside{
    border: 2px solid $yellow;
  
    .button{
      width: 250px;
      border-radius: 8px;
      padding: 8px 5px;
      font-weight: 600;
      border: 2px solid $yellow;
    }
  
    #sendButtonSelection{
      background: $yellow;
      margin-bottom: 5px; 
    }
  }

  @media screen and(max-width: 992px){
    display: none;
    position: fixed;
    z-index: 10000;
    margin: 0!important;
    padding: 0!important;
    width: 100%!important;
    height: 100vh!important;
    overflow-y: scroll;
    top: 0;
    left: 0;
    background-color: white;

    &.open{
      display: block;
    }
  }
}


.formule{
  display: flex;
  justify-content: space-between;
  align-items: flex-end!important;
  margin-bottom: 10px;

  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: flex-end !important;
    gap: 20px !important;

    .left{
      width: 100%;
    }
  }

  .choices{
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;

    .choicesItem{
      margin-bottom: 0 !important;
    }
  }
}

.options, .frais{
  .formule{
    margin-bottom: 30px;
    gap: 50px;
    align-items: center;

    .left{
      max-width: 420px;

      @media screen and (max-width: 640px) {
        max-width: inherit;
      }

      .price{
        margin-top: 10px;
      }
    }
  }
}

.resultsContent{
  display: flex;
  justify-content: space-between;
  gap: 10px;

  .owl-stage-outer{
    overflow: inherit !important;
  }

  .cardResult{
      width: calc(33% - 10px);
      background: white;
      border-radius: 16px;
      padding: 15px;
      position: relative;
      margin-bottom: 20px;
      box-shadow: 0 5px 15px rgba(black, .08);

      @media screen and (max-width: 640px) {
        width: 100%;
        display: flex;
        box-shadow: 0 0 15px rgba(black, .1);

        .bgImg{
          width: 240px;
          margin-right: 20px;
          border-radius: 8px;
          overflow: hidden;
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;

          img{
            width: 100%;
            object-fit: cover;
          }
        }

        .content{
          padding: 10px 0 !important;
        }
        
      }

      @media screen and (max-width: 450px) {
        display: block;

        .bgImg{
          width: 100%;
          margin-bottom: 15px;
          margin-right: 0;
        }
      }
      
      .owl-carousel-cardResult{
          width: 100%;
          height: auto;
          border-radius: 8px;
          overflow: hidden;
          position: relative;
          margin-bottom: 10px;

          .owl-stage-outer, .owl-stage, .owl-item{
              height: 100%;
          }

          .owl-dots{
              position: absolute;
              bottom: 15px;
              left:0;
              right: 0;
              text-align: center;
              z-index: 2;

              .owl-dot{
                  width: 8px;
                  height: 8px;
                  margin-right: 4px;
                  background: $grey2;
                  transition: .3s ease;

                  &.active{
                      background: white;
                      transition: .3s ease;
                  }
              }
          }

          .carouselItem{
              width: 100%;
              height: 200px;
              display: flex;
              justify-content: center;
              align-items: center;

              
              img{
                width: 100%;
                object-fit: cover;
              }
          }  
      }

      .content{
        .tags{
          margin-bottom: 5px;

          span{
            margin-bottom: 5px;
          }
        }
          
        
        .title{
            font-size: 20px;
            line-height: 26px;
            font-weight: 600;

        }

        .infos{
            color: $grey;
            margin-bottom: 10px;
        }

        .pictos{
          span{
            display: inline-block;
            padding-right: 10px;

            img{
              width: 12px;
              margin-right: 5px;
              display: inline-block;
              position: relative;
              top: -1px;
            }
          }
        }
      }

      .labels{
          display: flex;
          position: absolute;
          top: 15px;
          right: 15px;
          z-index: 2;


          span{
              display: inline-block;
              margin-left: 5px;

              &.like{
                  cursor: pointer;
              }
          }
      }
  }
}

.arianeFil{
  @media screen and (max-width: 640px) {
    display: none;
  }
}

.mobile{

  @media screen and (min-width: 640px) {
    display: none !important;
  }
}

.desktop{

  @media screen and (max-width: 640px) {
    display: none !important;
  }
}

.owl-carousel.off {
  display: flex;
}




/** MODAL "client/provider/partials/_products.html.twig" **/
.modal-contact-provider{
  z-index: 10000;
  .modal-content{
    border-radius: 16px;
    padding: 50px 30px;
    width: 60%;
    height: 700px;

    .first-step,.second-step,.third-step{
      height: 85%;

      .content-box{
        height: 95%;
      }
    }

    @media screen and(max-width: 992px){
      width: 95%;

      .third-step{
        #contentEventSectionSendSelection2{
          height: initial;
        }

        .buttonSend{
          max-width: 60%;
        }
      }
    }




  }
}


.technical-informations{
  li{
    list-style-type: initial;
    margin-left: 20px;
  }
}

.services-included{
  .item-box{
    border: 2px solid $grey;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;

    .label-box{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .label{
        font-weight: bold;

      }

      .icon{
        display: inline-block;
        width: 20px;
        height: 20px;
        background-image: url("../images/arrow/arrow-down.svg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    .content{
      margin-top: 20px;
      max-height: 0;
      overflow: hidden;
      display: none;

      &.open{
        max-height: 100%;
        display: block;
      }

      li{
        list-style-type: initial!important;
        margin-left: 20px;
      }
    }


  }
}


#ui-id-1{
  height: 300px;
  overflow-y:scroll;
}










