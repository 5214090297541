.super-admin-questionnaire-section{
  background-color: white;
  border:1px solid white;
  border-radius: 10px;
  padding: 10px;

  .admin-questionnaire-table{
    border-collapse: collapse;
    border:none;
    width: 100%;

    td{
      border:none!important;
      padding: 10px;
    }

    th{
      border-top: none!important;
      border-left: none!important;
      border-right: none!important;
      padding: 10px;
      border-bottom: 1px solid grey!important;
      color: #F1DC68;
      text-transform: uppercase;
      text-align: left!important;
      min-width: 70px;
    }

    td.bold{
      font-weight: bold;
    }

    td{
      a{
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}




/******* SHOW *******/

.super-admin-questionnaire-form-status{
  .row{
    width: 60%;
    margin: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    select{
      padding: 10px 20px;
      border:1px solid white;
      border-radius: 20px;
      option{
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    input{
      width: 60%;
      padding: 10px;
      border:1px solid white;
      border-radius: 20px;
    }

  }

  .row2{
    width: 90%;
  }
}



// link to create a quote
.super-admin-questionnaire-link{
  color: white;
  background-color: $darkBlue;
  border: 1px solid $darkBlue;
  border-radius: 20px;
  width: 200px;
  padding: 10px 5px;
  text-align: center;
}

.super-admin-questionnaire-link:hover{
  background-color: white;
  color: $darkBlue;
  cursor: pointer;
}


.super-admin-questionnaire-link2{
  color: black;
  background-color: $yellow;
  border: 1px solid $yellow;
  border-radius: 20px;
  width: 200px;
  padding: 10px 5px;
  text-align: center;
}

.super-admin-questionnaire-link2:hover{
  background-color: white;
  color: $yellow;
  cursor: pointer;
}



// every quotes

.super-admin-questionnaire-quote-pre-new-box{

  h2{
    font-size: 14px;
    font-weight: bold;
  }

  .filters{
    margin: 10px 0 20px;
    *{
      margin: 10px 20px;
    }
    .link-selected{
      text-decoration: underline;
    }
  }

  .quotes{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 60px;

    .block{
      width: 25%;
      margin: 10px 20px;
      background-color: white;
      border-radius: 20px;
      border:1px solid white;
      overflow: hidden;
      padding: 20px;
      -webkit-box-shadow: 5px 6px 20px -5px rgba(0,0,0,0.75);
      -moz-box-shadow: 5px 6px 20px -5px rgba(0,0,0,0.75);
      box-shadow: 5px 6px 20px -5px rgba(0,0,0,0.75);

      .img-box{
        margin-bottom: 20px;
      }

      .row-bottom{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .price{
          color: $darkBlue;
          border:1px solid $darkBlue;
          padding: 10px 20px;
          border-radius: 20px;
        }


        .status{
          color: white;
          border:1px solid $darkGrey;
          padding: 10px 20px;
          border-radius: 20px;
          background-color: $darkGrey;
        }

      }

    }

  }

}


/** CREATE - PROVIDER **/
.admin-questionnaire-create-section{



  .row{
    margin: 20px 0;

    label{
      font-weight: bold;
      font-size: 14px;
      display: block;
    }

    .date{
      select,input{
        width: 100px;
      }
    }

  }

  .products{
    h2{
      font-size: 20px;
      font-weight: bold;
    }

    .product-box{
      p{
        font-weight: bold;
      }

      .product-line-box{
        margin: 20px 0;
        width: 40%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        input[type='number']{
          width: 80px;
        }
      }
    }
  }

  .options{
    h2{
      font-size: 20px;
      font-weight: bold;
    }
    .product-option-box{
      margin: 20px 0;
      width: 40%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      input[type='number']{
        width: 80px;
      }
    }

  }


  @media screen and (max-width:390px) {
    label,.product-line-box,.product-option-box{
      width: 100%!important;
    }

    .date input{
      width: 50px!important;
    }
  }

}

.admin-questionnaire-create-resume{
  margin: 30px 0;
  h2{
    font-size: 20px;
    font-weight: bold;
  }

  p, p span{
    font-size: 14px;
    font-weight: bold;
    margin: 10px 0;
  }








}




.admin-questionnaire-create-btn{
  color: white;
  background-color: $darkBlue;
  border: 1px solid $darkBlue;
  border-radius: 20px;
  width: 200px;
  padding: 10px 5px;
  margin: 30px auto;
}

.admin-questionnaire-create-btn:hover{
  cursor: pointer;
  background-color: white;
  color: $darkBlue;
}

