/* HEADER */

header {
  top: 0;
  padding: 15px 30px;
  border: 1px solid #efefef;
}

header a {
  font-weight: 500;
}

.logo-tcheen {
  width: 130px;
}

.height-100 {
  min-height: calc(100vh - 65px);
}

.burger {
  width: 30px;
  height: 25px;
  span {
    width: 100%;
    height: 5px;
    border-radius: 3px;
  }
}

.page-provider,
.page-provider-information,
.page-provider-conditions,
.page-provider-pictures,
.questionnaire,
.questionnaire-success,
.survey,
.page-legal,
.picture-advice {
  header {
    box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 10;
  }
}

/*.provider {
  header {
    position: relative;
    padding: 15px 9%;
  }
}*/

/*****************************/

/* Footer */

footer {
  padding: 50px 0 60px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.logo-partner {
  max-width: 90px;
  max-height: 50px;
  box-sizing: content-box;
  padding: 5px 20px;
}

  .green-web-box{
    display: flex;
    justify-content: center;
    align-items: center;
  }

.footer-border {
  border: 1px solid;
}

/*****************************/
