@import '~uikit';
@import '~select2';
@import '~jquery-ui/themes/base/all.css';

@import 'common/_vars.scss';
@import 'common/_uikit-overwritten.scss';
@import 'common/_colors.scss';
@import 'common/_common.scss';
@import 'common/_text.scss';
@import 'common/_fonts';
@import 'common/_flash-messages.scss';
@import 'common/_form';
@import 'common/_header-footer.scss';
@import 'common/_padding-margin.scss';
@import 'common/_slider.scss';
@import 'common/_modal_login.scss';

@import '_home.scss';
@import '_contact.scss';
@import '_ethics.scss';
@import '_admin.scss';
@import '_admin_questionnaire.scss';
@import '_client.scss';
@import '_identification.scss';
@import '_questionnaire.scss';
@import '_survey.scss';
@import '_account.scss';
@import '_blog.scss';
@import '_concept.scss';
@import '_faq.scss';
@import '_provider-profile.scss';
@import '_provider-booking.scss';
@import '_provider-admin.scss';
@import "product_edit_modal2";
@import '_search.scss';
@import '_tablet.scss';
@import '_mobile.scss';
@import '_provider_profile_environment_modal.scss';
@import '_provider-calendar.scss';
@import '_search_drink_modal.scss';
@import '_registration.scss';
@import '_provider-pictures.scss';
@import "modal_blog_newsletter";


@import "responsive_provider-profile";
@import "responsive_provider_calendar";
@import "responsive_provider";
@import "responsive_client_event";
@import "responsive_client_tchat";

