@media screen and (max-width: 640px) {

  /*************  COMMON  *************/

  p {
    line-height: 1.5;
  }

  .font-10--s {
    font-size: 10px;
  }
  .font-12--s {
    font-size: 12px;
  }
  .font-14--s {
    font-size: 14px;
  }
  .font-16--s {
    font-size: 16px;
  }
  .font-18--s {
    font-size: 18px;
  }
  .font-20--s {
    font-size: 20px;
  }
  .font-26--s {
    font-size: 26px;
  }

  .padding-t-40--s {
    padding: 40px 0 0;
  }

  .padding-v-40--s {
    padding: 40px 0;
  }

  .padding-15--s {
    padding: 15px;
  }

  .padding-25--s {
    padding: 25px;
  }

  .mb-40--s {
    margin-bottom: 40px;
  }

  .container {
    padding: 0 25px;
  }

  .uk-modal-body {
    padding: 15px;
  }

  footer {
    display: none;
    > * {
      text-align: center;
    }
  }

  #open-footer {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding-bottom: 20vh;
  }

  .picto-more.picto-more--small {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }

  .button {
    padding: 0 15px /*!important*/;
  }

  .admin-body {
    padding: 20px;
  }

  .form-label {
    font-size: 12px;
  }

  /************************************/



  /************* FLASH MSG ***********/

  .flash-msg-title {
    font-size: 16px;
  }

  /************************************/



  /* PAGES ERROR */

  .error {
    img {
      max-width: 300px;
      margin: auto;
      display: block;
    }
  }

  /*****************************/



  /* PAGES ERROR */

  .form-title {
    font-size: 20px;
  }

  .form-submenu {
    span {
      margin: 0 10px;
    }
  }

  /*****************************/



  /**************  HOME  **************/

  #modal-search-mobile {
    padding: 45px 30px;
  }

  .section-home-1 {
    height: auto;
    padding: 40px 0 50px;
  }

  .section-home-2, .section-home-3, .section-home-4 {
    padding: 40px 0;
  }

  .home-companies img {
    max-width: 130px;
  }

  .section-home-5, .section-home-3 {
    padding: 50px 0;
    .uk-slider {
      max-width: calc(100% - 35px);
      margin: auto;
    }
    .uk-slidenav {
      padding: 5px 10px;
    }
  }

  /************************************/



  /************  CONCEPT  *************/

  .section-concept-1 {
    height: auto;
    padding-bottom: 35px;
  }

  .section-concept-2 {
    padding-bottom: 30px;
  }

  .concept-2-card {
    margin-top: 0;
    padding: 30px 0;
    .uk-grid:nth-of-type(even) {
      flex-direction: column-reverse;
    }
    .uk-flex {
      height: 200px;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  .section-concept-3 {
    .container {
      padding: 50px 25px 140px;
    }
  }

  .concept-partners-ctn {
    padding: 0;
    background-color: $lightGrey !important;
    border-radius: initial;
    img {
      width: 100%;
    }
  }

  .concept-partners-ctn .uk-position-small {
    margin: 15px 0;
  }

  /************************************/



  /**************  BLOG  **************/

  .blog {
    aside {
      margin-right: -30px;
      margin-left: -30px;
    }
  }

  .blog-article {
    height: auto;
    .article-share {
      right: 20px !important;
      left: auto !important;
    }
  }
  .blog-article-sum {
    padding: 20px;
    min-height: 200px;
  }

  .blog-article-sum-img {
    height: 200px;
  }

  .last-articles-pagination img {
    width: 40px;
  }

  .newsletter-form input, .newsletter-form input:focus {
    width: 150px;
  }

  // Blog show
  .blog-article-show {
    margin-right: -30px;
    margin-left: -30px;
    padding: 30px;
  }

  .blog-article-img {
    height: 200px;
  }

  /************************************/



  /*************  CLIENT  *************/

  .client {
    .uk-slider {
      padding-bottom: 70px;
    }
    h1, .uk-subnav {
      padding: 0;
    }
  }

  .client-profile-ctn {
    margin-top: 40px;
  }
  .client-picture-ctn {
    margin: -60px auto auto !important;
  }
  .card-provider-pictures {
    height: 170px;
  }
  .event-aside-ctn {
    margin-left: -30px;
    margin-right: -30px;
  }
  .event-show-sum {
    &:first-of-type {
      display: block;
      margin: 0 !important;
      svg {
        margin: 0 auto 5px !important;
        display: block;
      }
    }
  }

  .booking-ctn {
    padding: 30px 25px 50px;
  }

  .selection-provider-sum {
    padding: 40px 20px 30px;
    margin-bottom: 50px;
    h2.font-14 {
      margin-bottom: 20px;
    }
    img {
      max-height: 210px;
    }
  }

  .selection-provider-rate {
    position: absolute;
    top: -25px;
    right: 20px;
  }

  .selection-sum {
    padding: 25px;
  }

  .chat {
    padding: 0 20px 20px;
  }

  .chat-form {
    display: block;
    textarea {
      width: 100%;
      padding: 20px;
    }
    .button {
      margin: auto;
      display: block;
    }
  }

  #chat-scroll-top {
    margin-top: 10px;
  }

  .chat-message-ctn {
    padding: 25px;
    width: 75%;
  }

  /************************************/



  /************  PROVIDER  ************/

  .dashboard {
    .uk-grid + .uk-grid, .uk-grid > .uk-grid-margin, * + .uk-grid-margin {
      margin-top: 20px;
    }
  }
  .provider-account-img {
    display: block;
    margin: 0 auto 10px !important;
  }

  .statistics-menu {
    flex-wrap: wrap;
    li {
      text-align: center;
      width: 100%;
      margin-bottom: 15px;
    }
    a {
      margin-right: 0 !important;
    }
  }

  .provider-booking {
    .client-picture {
      margin: 0 auto 20px !important;
    }
  }

  .product-table {
    padding: 20px !important;
    th, td {
      font-size: 12px !important;
    }
  }

  /************************************/



  /***************  FAQ  **************/

  .faq, .instructions {
    main.bgc-yellow, main.bgc-blue {
      padding: 80px 0;
    }
  }

  .faq-illus {
    max-width: 180px;
    max-height: 120px;
  }

  .faq-btn {
    max-width: 300px;
    padding: 30px;
    margin: 20px auto;
  }

  /*****************************/



  /* ETHICS */

  .ethics-bg {
    height: 250px;
    background-image: url("../images/ethics/fond-notre-ethique-mobile.png");
  }

  .ethics-title {
    padding-top: 170px;
    h1:after {
      top: -20px;
    }
  }

  .ethics-container {
    margin: 40px auto 0;
  }

  .ethics-container .bgc-white {
    padding: 0 0 70px;
  }

  .ethics-environmental-points {
    .padding-30 {
      padding: 15px;
    }
  }

  .ethics-criteria {
    margin: 0;
    display: block;
    > * {
      text-align: center;
      width: 100%;
    }
    img {
      display: block;
      margin: 0 auto 20px;
    }
  }

  .ethics hr.criteria {
    width: 80px;
    margin: 30px auto;
  }

  .section-ethics-partners img {
    max-height: 90px;
  }

  .ethics-partner{
    width: initial;
  }

  /*****************************/



  /* INSTRUCTIONS */

  .instructions {
    h3 {
      margin-left: 0 !important;
    }
    .bgc-light-pink h2 {
      margin-top: 0;
    }
    .ethics-provider-link {
      margin: 30px 0;
    }
  }

  .instructions-header {
    h1 {
      font-size: 26px !important;
    }
  }
  .instructions-header-img {
      margin: 15px auto 0 !important;
  }

  .instructions-step img {
    margin-bottom: 30px !important;
  }

  /*****************************/



  /* SEARCH */

  .search-map-screen {
    height: 80px;
  }

  .search-providers-ctn {
    height: auto;
    overflow: auto;
  }

  /*****************************/



  /* SURVEY */

  .survey form hr {
    margin: 50px auto;
  }

  .survey-explanation {
    padding: 30px;
  }

  .survey-explanation-warning {
    padding: 35px;
  }

  /*****************************/



  /* QUESTIONNAIRE */

  .questionnaire-card {
    width: 80px;
    height: 80px;
    img {
      width: 30px;
      height: 30px;
      margin: 5px auto;
    }
    span {
      height: 20px;
      font-size: 10px;
    }
    .slider-range {
      width: 100px;
    }
    &.selected {
      &:after {
        margin: 15px auto;
      }
    }
  }

  .section-questionnaire.section-date {
    .uk-grid .uk-width-1-1 {
      margin-bottom: 20px;
    }
  }

  /*****************************/



  /* USER */

  .identification {
    .form-login {
      border-top: 2px solid $grey;
      padding: 0;
      .bgc-white {
        padding-bottom: 40px;
      }
    }
    h1 {
      margin-top: 30px;
    }
  }

  .form-login .bgc-white {
    padding: 15px;
  }

  /*****************************/
}
//modal-event

@media screen and (max-width: 350px) {
  .selectEventModalSafari select {
    padding: 0px 0px 0px 60px;
  }
}
@media screen and (min-width: 350px) and (max-width: 400px) {
  .selectEventModalSafari select {
    padding: 0px 0px 0px 85px;
  }
}
@media screen and (min-width: 400px) and (max-width: 500px) {
  .selectEventModalSafari select {
    padding: 0px 0px 0px 100px;
  }
}
@media screen and (min-width: 500px) and (max-width: 800px) {
  .selectEventModalSafari select {
    padding: 0px 0px 0px 210px;
  }
}

.selectEventModal select{
  text-align: center;
  text-align-last: center;
}