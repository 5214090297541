.provider-booking .uk-subnav-pill > .uk-active > a {
  background-color: transparent;
  color: $yellow;
}

.provider-booking .uk-subnav-pill a {
  font-size: 14px;
  &:hover {
    color: $yellow;
    background-color: transparent;
  }
}

.booking-ctn {
  padding: 60px 60px 60px 20%;
  overflow: scroll;
  height: calc(100vh - 185px);
}

.provider-booking-event {
  border: 2px solid $grey;
  max-width: 400px;
  margin: auto;
}

.provider-booking-event-place {
  border-bottom: 2px solid $grey;
}

.provider-booking-event-date {
  border-right: 2px solid $grey;
}

.client-picture {
  width: 100px;
  height: 100px;
  border: 10px solid $white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
}

.chat-start {
  padding: 40% 50px;
}

.chat-current {
  padding: 40px 0 0;
}

.chat {
  overflow: scroll;
  height: calc(100vh - 400px);
  padding: 0 30px;
}

.chat-date {
  margin-bottom: 30px;
  &:after {
    display: block;
    content: '';
    flex: 1;
    height: 1px;
    background-color: $lightGrey;
    margin-left: 20px;
  }
}

.chat-message-ctn {
  padding: 35px;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 40px;
  width: 70%;
  word-break: break-word;
  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: -30px;
  }
  p,li {
    font-size: 12px!important;
    font-weight: 400!important;
  }
  a{
    color: blue!important;
    text-decoration: underline;
  }
  strong{
    font-weight: bold;
  }

}

.uk-flex-left {
    .chat-message-ctn:after {
        border-bottom: 30px solid $lightGrey;
        border-right: 30px solid transparent;
        left: 50px;

      }
  
}

.uk-flex-right {
    .chat-message-ctn:after {
        border-bottom: 30px solid rgba($yellow, 0.7);
        border-left: 30px solid transparent;
        right: 50px;
      }
}


.chat-form {
  border-top: 2px solid $lightGrey;
  textarea {
    font-family: 'Poppins', 'sans-serif';
    border: none;
    resize: none;
  }
}

.booking-msg-alert {
  top: 30px;
  right: 25px;
}

.booking-msg-alert-bubble {
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.statistics-menu {
  a:hover, a.active {
    color: $yellow;
    border-bottom: 1px solid $yellow;
  }
}
.green-event-menu {
  a:hover, a.active {
    border-bottom: 5px solid $yellow;
  }
}

.reviews-table, .reviews-stars-table {
  border: 2px solid $grey;
  th, td {
    border: 2px solid $grey;
  }
}

.reviews-table tbody {
  max-height: 100px;
  overflow: scroll;
}

.review-content-overflow {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.review-event-hidden {
  border: 2px solid $grey;
  display: none;
  width: 300px;
}

#review-event-review-modal {
  display: none;
}




@media screen and (max-width: 640px){
  .green-event-menu{
    flex-direction: column;
    li{
      margin-bottom: 20px;
    }
  }

  .green-webinaire-list{
    .img-and-text{
      flex-direction: column;

      .text-box{
        text-align: center;
      }
    }
  }

  .green-partners{
    .green-partners-join{
      flex-direction: column;
    }
  }

  .green-advice{
    .content{
      flex-direction: column;
      flex-wrap: wrap;
      &>div{
        width: 100%;
      }
    }
  }

}

/*******************************************************************
                          modal insurance
 *******************************************************************/
.modal-insurance{
  .content{
    width: 70%!important;
    height: 60vh;
    padding: 30px;
    background-color: $white;
    overflow-y: scroll;
    border:1px solid $white;
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;

    .caution{
      color: $red;
      font-weight: 600;
      font-size: 30px;
      text-align: center;
      margin-bottom: 25px;
    }

    .info{
      font-size: 16px;
      font-weight: 400;
      width: 70%;
      margin: 25px auto;
    }

    form{
      .field-box{
        width: 50%;
        height: 170px;
        margin: 0 auto;
        border: 2px dashed $grey2;
        border-radius: 10px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;

        &:hover{
          cursor: pointer;
        }

        label{
          font-weight: 400;
          font-size: 16px;
          color: $grey2;
        }

      }

      #insurance{
        display: none;
      }

      .btn-box{
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        button{
          padding: 10px 20px;
        }
      }

    }
  }

  @media screen and(max-width: 992px){
    .content{
      width: 90%!important;
      height: 90vh;


      .caution{
        font-size: 24px;
      }

      .info{
        width: 95%;
      }
    }


  }
}








