/* QUESTIONNAIRE */

.questionnaire-msg-error {
  display: none;
}

.questionnaire-progress {
  max-width: 370px;
  margin: 0 auto 40px;
}

.section-questionnaire {
  max-width: 1000px;

  .input-container{
    max-width: 400px;
  }

}

.questionnaire {
  min-height: 100vh;
  background-color: $lightGrey;
  .uk-container {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  section:not(:first-of-type) {
    display: none;
  }
  select {
    width: 380px;
  }
  input[type=text], input[type=email], input[type=password] {
    font-style: italic;
    max-width: 400px;
  }
  input.uk-form-width-xsmall {
    padding: 0 5px !important;
  }
  label.active {
    border: 2px solid $yellow;
  }
  textarea {
    height: 300px;
    width: 100%;
    padding: 15px;
    border-radius: 25px;
  }
  .uk-select:not([multiple]):not([size]) {
    background-position: 95% 50%;
  }
}

.checkbox-circle {
  border-radius: 0.2em;
  width: 20px !important;
  height: 20px !important;
  background-color: $white;
  border: 1px solid #D8D8D8;
  margin-right: 15px;
  &:checked, &:indeterminate {
    background-color: $green;
    color: $white;
  }
}

.questionnaire-card {
  cursor: pointer;
  display: block;
  background-color: $white;
  border-radius: 15px;
  padding: 10px;
  margin: 10px 5px;
  width: 140px;
  height: 140px;
  box-sizing: border-box;
  > * {
    text-align: center;
  }
  img {
    max-width: 50px;
    height: 50px;
    display: block;
    margin: 10px auto;
  }
  span {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.2;
  }
  &.active {
    background-color: $yellow;
  }
  &.selected {
    background-color: $yellow;
    &:after {
      content: '';
      display: block;
      width: 85%;
      border-bottom: 4px solid $yellow;
      margin: 7px auto;
      border-radius: 4px;
    }
  }
}

#rgpdAgreement, #conditionAgreement, #sendNewsletter, #sendEmailOnOtherActivity, #sendEmailOnSelectionActivity {
  height: 25px;
  width: 25px;
  margin-right: 10px;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  background-color: $white;
  &:checked {
    background-color: $green;
    color: $white;
  }
}

.js-questionnaire-tag {
  margin-top: 20px;
  display: none;
}

.input-tag--white {
  color: #959090;
}

.questionnaire-success {
  min-height: 100vh;
  background-color: $lightGrey;
  background-image: url("../images/identity/fond-symboles.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  header {
    box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
  }
  h1 {
    margin-bottom: 30px;
    font-size: 22px;
  }
  p {
    margin: 0 auto 30px;
    max-width: 550px;
  }
}


/* SLIDER RANGE - jQuery-ui */

.questionnaire {
  .slider-range {
    margin: 40px auto 50px;
  }

  .slider-range .ui-state-default {
    border: none !important;
    border-radius: 50%;
    background-color: $yellow !important;
    box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.1);
    width: 30px !important;
    height: 30px !important;
    top: 0;
    outline: none;
  }

  .slider-range.ui-widget-content {
    border: none !important;
    background-color: $white !important;
    border-radius: 15px;
    text-align: center !important;
  }

  .ui-slider-range {
    background-color: $white !important;
  }

  .slider-range-value {
    display: block;
    font-size: 16px;
    font-weight: bold;
    color: $black;
    position: absolute;
    top: -28px;
    left: 50%;
    transform: translateX(-50%);
  }

  .ui-slider-horizontal {
    height: 10px !important;
  }
}


/**********************************************/


/* DATEPICKER - jQuery-ui */

.questionnaire .ui-datepicker {
  border: none !important;
  border-radius: 15px;
  width: 500px;
  .ui-state-default {
    background-color: inherit;
    border: none;
    text-align: center;
    font-size: 16px;
  }
  .ui-state-active {
    background-color: $grey;
    border: none;
    border-radius: 20px;
    color: $black;
  }
  .ui-widget-header {
    background-color: inherit;
    border: none;
  }
  .ui-state-highlight {
    color: $black;
  }
  .ui-datepicker .ui-datepicker-next {
    right: 20%;
  }
  .ui-datepicker .ui-datepicker-prev {
    left: 20%;
  }
}

/**********************************************/



/* ADMIN */

.admin-questionnaire {
  table {
    border: 2px solid $grey;
    th, td {
      border: 2px solid $grey;
    }
  }
}

/**********************************************/