/* HOME */

.home header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 67.02px;
  border: 1px solid #efefef;
}

.section-home-1 {
  height: calc(100vh - 30px);
  box-sizing: border-box;
  #home-questionnaire-link {
    border-bottom: 2px solid;
    line-height: 1;
    text-shadow: 2px 2px $yellowAlt, 2px -2px $yellowAlt, -2px 2px $yellowAlt, -2px -2px $yellowAlt;
  }
}
#js-pop-up-landing span{
  color: #FFFFFF;

}#js-pop-up-landing img{
  color: #FFFFFF;

}
.pop-up-landing_page {
  position: absolute;
  top: 67.02px;
  width: 100%;
  min-height: 60px;
  background-color: #5D5D5D;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

}
.pop-up-landing_page a {
  height: 28px;
  width: 766px;
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  cursor: pointer;
  text-decoration: underline;}
.bandeau-description{
  font-size: 17px;

}
@media screen and (min-width: 1200px){
  .mobil-connexion{
display: none  }
  }
@media screen and (max-width: 1200px){
  .pop-up-landing_page {
    position: initial;
  }
  .bandeau-description{
    width: 100%;
  }
}

@media screen and (max-width: 320px) {
/*  .pop-up-landing_page a {
    font-size: 12px;
  }
  .bandeau-description{
    font-size: 12px;

  }*/
  .pop-up-landing_page div {

  }
}
.dropdown-z-index{
  z-index: 20000;
}
.home-select {
  padding-left: 20px !important;
  width: 79% !important;
}

.home-placeholder::placeholder {
  font-style: normal !important;
}
// allow city choice to be visible
#ui-id-2 {
  z-index: 10000;
}

.home-search {
  box-shadow: rgba(0, 0, 0, 0.15);
  padding: 35px 35px 25px 35px;
  width: 400px;
  margin: 25px;
  box-sizing: border-box;
  input,
  select,
  ::placeholder {
    border: none;
    line-height: 60px;
    height: 60px !important;
    font-size: 16px;
    font-weight: 300;
    color: #302f30 !important;
    opacity: 1;
  }
  input {
    font-family: "Poppins", "sans-serif";
  }

}

.home-search-picto {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.home-illustration {
  margin-left: 70px;
  img {
    margin-right: 60px;
  }
}

.section-home-2 {
  background-color: rgba(239, 239, 239, 0.5);
  padding: 90px 0;
  .home-identity {
    position: absolute;
    top: -30px;
    right: 8px;
  }
}

.home-picto {
  margin-bottom: 20px;
  height: 75px;
}

.home-arrow {
  height: 75px;
  img {
    width: 50px;
    margin: 0 10px;
  }
}

.home-more {
  letter-spacing: 1px;
  font-size: 16px;
}

.section-home-3 {
  padding: 70px 0;
  .home-identity {
    margin-right: 10px;
    margin-top: -10px;
  }
}

@media screen and (min-width: 960px){
  .section-home-3 {
    a>div{
      transition: transform 0.3s ease-in-out;
    }
    a:hover>div{

      transform: scale(1.05);
    }
    .show-lieu{
      display: none;
    }
    .show-food{
      display: none;
    }
    .show-tente{
      display: none;
    }
    .show-boisson{
      display: none;
    }
    .show-service{
      display: none;
    }
    .color-hover-lieu:hover .show-lieu{
      display: block;
    }
    .color-hover-food:hover .show-food{
      display: block;
    }
    .color-hover-tente:hover .show-tente{
      display: block;
    }
    .color-hover-boisson:hover .show-boisson{
      display: block;
    }
    .color-hover-service:hover .show-service{
      display: block;
    }
    .color-hover-lieu:hover>div{
      background: rgba(108, 190, 153, 0.45);
    }
    .color-hover-food:hover>div{
      background: rgba(30, 167, 172, 0.45);
    }
    .color-hover-tente:hover>div{
      background: rgba(231, 64, 17, 0.45);
    }
    .color-hover-boisson:hover>div{
      background: rgba(60, 68, 136, 0.45);
    }
    .color-hover-service:hover>div{
      background: rgba(239, 216, 87, 0.45);
    }

  }
  .home-search-submit{
    background-color: #FFFFFF;
  }
}

@media screen and (max-width: 960px) {
  .home-search-submit{
    background-color: #efd857;
border: 0;  }
  input[type=text], select {
/*    text-align: center;
    text-align-last: center;*/
  }
}
.block-5-pictures {
  height: 460px;
  margin-left: 0;
  img {
    height: 100%;
  }
  .absolute-title-bottom {
    position: absolute;
    bottom: 20px;
    left: 20px;
    line-height: 1;
  }
  .absolute-title-center {
    position: absolute;
    top: 40%;
    width: 100%;
    text-align: center;
  }
}

.section-home-4 {
  background-color: $lightPink;
  padding: 60px 0 80px 0;
  .home-identity {
    z-index: -1;
    position: absolute;
    top: 50%;
    right: 55%;
    transform: translate(50%, -50%);
  }
}
.home-reviews-ctn {
  box-shadow: 0 0 15px -3px rgba(0, 0, 0, 0.15);
}

.home-review {
  padding: 50px 100px;
  width: 100%;
  box-sizing: border-box;
  //box-shadow: 0 0 15px -3px rgba(0,0,0,0.15);
}

.home-reviews,
.home-review {
  overflow: visible;
}

.home-review-pic {
  width: 160px;
  height: 160px;
  border: 10px solid $white;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 0 0 15px -3px rgba(0, 0, 0, 0.15);
  margin: -100px auto 40px;
  z-index: 1000;
}

.home-review-client,
.home-review-company {
  top: -15px;
  border-radius: 13px;
  padding: 5px 15px;
  z-index: 10;
}

.home-review-client {
  right: 65px;
}

.home-review-company {
  left: 65px;
}

.home-companies {
  min-height: 120px !important;
  height: 120px;
  border-radius: 40px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 0 15px -3px rgba(0, 0, 0, 0.15);
  ul {
    height: 120px;
  }
  img {
    filter: grayscale(1);
    max-width: 150px;
    height: auto;
    &:hover {
      filter: none;
    }
  }
}

.home-companies-ctn {
  margin: 0 100px;
  overflow: hidden;
}

.section-home-5 {
  background-color: $lightGreen;
  padding: 90px 0;
}

.home-blog-title {
  margin-right: 100px;
}

.home-blog-slider {
  width: calc(100% - 80px);
  /*h4 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }*/
}

.home-search-submit {
  border-radius: 24px !important;
  font-weight: 700 !important;
}

.home-search-placeholder::placeholder {
  font-style: normal !important;
}
#logo-cross-home{
  cursor: pointer;
}
.modalDatepicker {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}