/* FORM */

.form-container {
  max-width: 800px;
  margin: auto;
  padding: 30px 30px 80px;
}

.form-title {
  text-align: center;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 70px;
}

.form-label {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
}

.form-field {
  border-radius: 30px;
  font-weight: 200;
  padding: 0 20px;
  font-size: 14px;
  &.form-field-xsmall {
    padding: 0 10px;
  }
}

.form-field:not(textarea) {
  height: 55px !important;
}

textarea.form-field {
  padding: 15px 20px;
}

.form-field-xsmall {
  width: 60px
}

.form-field-small {
  width: 90px
}

.form-button, .form-button:hover, .form-button:active {
  background-color: $yellow;
  color: white;
  border-radius: 25px;
  height: 50px;
  font-size: 16px;
  font-weight: 500;
  &.bgc-red {
    background-color: $red !important;
  }
  &.bgc-green {
    background-color: $green !important;
  }
  &.bgc-blue {
    background-color: $blue !important;
  }
}

.form-button:disabled{
  background-color: $grey!important;
  &:hover{
    background-color: $grey!important;
  }
}

.form-submenu {
  span {
    margin: 0 20px;
  }
}

.cke_contents {
  border: 1px solid #efefef !important;
  border-radius: 0 0 25px 25px !important;
}

.cke_top {
  border-radius: 25px 25px 0 0 !important;
  border: 1px solid #efefef !important;
  background: white !important;
}

.cke_bottom {
  display: none !important;
}

.cke_inner {
  border-radius: 25px !important;
  border: 1px solid #efefef !important;
}

.cke {
  border: none !important;
}

.cke_editable {
  font-size: 14px;
  font-weight: 200;
  font-family: "Poppins", "sans-serif";
}

.input-reset {
  appearance: none;
  background-color: initial;
  border: none;
  color: $black;
  font-size: 14px;
  text-decoration: underline;
  width: auto;
  margin-right: 10px;
}

.uk-form-custom img {
  width: 100px;
}
