.provider-profile-env-modal-container{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0,0,0,0.8);
  display:none;
  justify-content: center;
  align-items: center;


  .provider-profile-env-modal{
    width: 80%;
    height: 80%;
    min-height: 30vh;
    background-color: white;
    border-radius: 20px;
    padding-bottom: 10vh;

    .cross-div{
      text-align: right;
      padding: 10px  30px;
      #cross{
        color: $darkGrey;
        font-family: sans-serif;
        font-size: 30px;
      }
      #cross:hover{
        color: $grey;
        cursor:pointer;
      }
    }

  }


}

/***************************************************

Partial twig : client/provider/_env_modal.html.twig

 ***************************************************/

.provider-profile-partial-modal-content{
  padding: 10px;
    .title{
      font-weight: bold;
      font-size: 1.4em;
      text-align:center
    }

    .text{
      text-align: center;
      margin: 30px 0;
    }

    .row{
      display: flex;
      justify-content: space-evenly;
      align-items: flex-start;
      flex-wrap: wrap;

      .block{
        width: 15%;
        min-height: 10vh;
        min-width: 130px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .circles-row{
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          width: 80%;
          .circle{
            width: 20px;
            height: 20px;
            border-radius: 10px;
            border:1px solid $grey;
          }
          .circle.full{
            background-color: $blue;
          }
        }
      }
      .block.selected{
        border:1px solid $blue;
        border-radius: 10px;
      }

    }
}
















