html, h1, h2, h3, h4, h5, h6, button {
  font-family: 'Poppins', sans-serif !important;
  color: $black;
  margin: 0;
  padding: 0;
}

html, p, ul, li, span {
  font-size: 16px;
  //font-weight: 400;
}

a, .uk-link {
  color: inherit;
}

a:hover, .uk-link:hover, .uk-link-toggle:hover .uk-link, .uk-link-toggle:focus .uk-link {
  color: inherit;
  text-decoration: none;
}

ul, ol {
  padding: 0;
}

p, ul, ol, dl, pre, address, fieldset, figure {
  margin: 0;
}

li {
  list-style: none;
}

em {
  color: inherit !important;
}

.text-justify\@s {
  @media screen and (min-width: 960px) {
    text-align: justify;
  }
}

.uk-button {
  text-transform: none;
  padding: 0 20px;
  line-height: 50px;
  &.bgc-yellow {
    &:hover, &:focus, &:active {
      background-color: darken($yellow, 1);
    }
  }
}

.uk-button-primary {
  background-color: $blue;
  &:hover, &:focus, &:active {
    background-color: $altBlue;
  }
}

.uk-button-danger {
  background-color: $red;
  &:hover, &:focus, &:active {
    background-color: $altRed;
  }
}

.uk-button-secondary {
  background-color: $yellow;
  &:hover, &:focus, &:active {
    background-color: darken($yellow, 0.2);
  }
}

.button-reset {
  background-color: inherit;
  border: none;
  cursor: pointer;
}

.uk-dropdown {
  min-width: 250px;
}

.uk-container-medium {
  max-width: 1080px;
  padding-left: 30px;
  padding-right: 30px;
}

label {
  //margin-bottom: 10px;
  display: inline-block;
}

.uk-input::placeholder {
  color: #979797 !important;
  font-style: italic;
}

.uk-textarea {
  resize: none;
  &::placeholder {
    font-style: italic;
  }
}

.uk-button-link {
  color: $black;
  font-size: 14px;
  text-decoration: underline;
  line-height: 30px;
  padding: 0 10px;
  &:hover, &.focus, &:active {
    color: $black;
    font-weight: 500;
  }
}

/* A REMETTRE SUR DES BALISES MIEUX CIBLÉES */
/*.uk-select:not([multiple]):not([size]) {
  background-image: url("../images/arrow/arrow-down.svg");
  background-size: 10px;
  background-position: 90% 50%;
}*/


.uk-search-default .uk-search-input {
  height: 50px;
}

.uk-search-default .uk-search-icon {
  width: 50px;
}

.uk-search-default .uk-search-icon:not(.uk-search-icon-flip) ~ .uk-search-input {
  padding-left: 45px;
}

.uk-table-hover > tr:hover, .uk-table-hover tbody tr:hover {
  background: rgba($blue, 0.2);
}

th.yellow {
  color: $yellow !important;
}

.uk-progress {
  border-radius: 5px;
  background-color: $white;
  height: 10px;
}

.uk-progress::-moz-progress-bar {
  background-color: $yellow;
}

.uk-progress::-webkit-progress-value {
  background-color: $yellow;
}

.uk-dropdown {
  min-width: 180px;
}

.uk-dropdown-nav > li > a {
  color: $darkGrey;
}

[class*="uk-dropdown-bottom"] {
  margin-top: 23px;
}

.uk-offcanvas-bar {
  background-color: $white;
  li, a {
    color: $lightBlack !important;
  }
}

.uk-offcanvas-close {
  color: $lightBlack !important;
}


.subnav-underline {
  a {
    color: $black;
    text-transform: none;
    font-weight: 300;
    padding: 0;
  }
  .uk-active > a {
    background-color: transparent;
    color: $black;
    text-decoration: underline;
    font-weight: 600;
  }
}

.uk-dotnav {
  margin-top: -30px !important;
  margin-bottom: 25px;
  z-index: 100;
  position: relative;
}

.uk-light .uk-dotnav > * > * {
  width: 14px;
  height: 14px;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
}

.uk-light .uk-dotnav > .uk-active > * {
  background-color: #fff;
}

.uk-slidenav svg:nth-of-type(2) {
  display: none;
}

.uk-overlay-primary {
  background: rgba(34, 34, 34, 0.3);
}

/**** EDITOR ****/
.editor{
  ul li, ol li{
    list-style-type: inherit;
    font-weight: 400;
  }

  a{
    text-decoration: underline;
    color: $aqua;
  }
}


/**** LIGHTBOX = DIAPO ***/
.uk-lightbox{
  z-index: 999999!important;
}

@media screen and (max-width: 640px) {
  .uk-display-block--s {
    display: block !important;
  }
}

.uk-background-primary {
  background-color: #fff;
}

.uk-background-secondary {
  background-color: $yellow;
}