.ethics-bg {
  background-image: url("../images/ethics/fond-notre-ethique.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(100vh - 130px);
}

.ethics {
  h1:after {
    content: url("../images/identity/traits-blancs.svg");
    display: block;
    position: absolute;
    top: -30px;
    right: -20px;
  }
  h2 {
    max-width: 550px;
    margin: auto;
  }
  p {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  hr.green {
    width: 80px;
    margin: 30px auto;
    border: 1px solid $green;
  }
  hr.criteria {
    width: 80px;
    border: 1px solid $lightBlack;
    margin: 30px 0;
  }
  hr:not(.green, .criteria) {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}

.ethics-container {
  max-width: 900px;
  margin: -20% auto 0;
  box-sizing: border-box;
  padding: 0 30px;
  .bgc-white {
    padding: 50px 100px 70px;
  }
}

.ethics-questionnaire-round {
  width: 30px;
  height: 30px;
  background-color: rgba($green, 0.3);
  margin: 5px;
  border-radius: 50%;
  &.active {
    background-color: $green;
  }
}

.ethics-criteria {
  max-width: 600px;
  margin-left: 18%;
  img {
    margin-right: 50px;
    max-width: 90px;
  }
}

.ethics-partner-box{
  align-items: center;
}

.section-ethics-partners {
  padding: 70px 0 120px;
}

.ethics-partner {
  width: 20%;
  margin: 10px;
  img {
    width: 100%;
    height: auto;
  }
}

.ethics-environmental-points {
  max-width: 700px;
  border: 1px solid #d8d8d8;
}
