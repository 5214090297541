.update-info-link{
  position: relative;
  &:after{
    content: "";
    background-image: url("../images/picto/pencil.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 2px;
    left: -30px;
    width: 20px;
    height: 20px;
  }
}

.user-client-picture{
  width: 100px;
  height: 100px;
  border: 5px solid $yellow;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
}

.img-link{
  position: relative;
  overflow: hidden;
  .elm-photo{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.50);
    background-image: url("../images/picto/photo.svg");
    background-repeat: no-repeat;
    background-size: 25px;
    background-position: center;
  }
}

.invoice-link{
  position: relative;
  &:after{
    content: "";
    background-image: url("../images/picto/export.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    right: -25px;
    width: 20px;
    height: 20px;
  }
}


.user-picture {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-repeat: no-repeat;
}

.client-picture-review {
  background-size: 90%;
  background-position: center 10px;
  background-color: $lightGrey;
  margin: 0 auto 10px;
}
@media screen and (max-width: 400px) {
  .logo-tcheen{
    width: 50px;
  }
}
.company-input {
  border-radius: 50%;
  border: 1px solid $grey;
  position: relative;
  width: 22px;
  height: 22px;
  margin-right: 10px;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $yellow;
    border-radius: 50%;
    width: 12px;
    height: 12px;
  }
}

.event-show-sum {
  svg {
    height: 16px;
  }
}

.event-notifications-title {
  margin: 0 12px;
}

.event-notifications-ctn {
  padding: 40px 28px 20px 28px;
}

.event-aside-ctn {
  position: fixed; top: 233px; width: 276px;
  aside {
    padding: 50px 50px 35px 50px;
    width: 380px;
    box-sizing: border-box;
    z-index: 1;
  }
  img {
    height: 80px;
    box-sizing: border-box;
    margin-left: 50%;
    transform: translateX(-30px);
  }
}

/* SELECTION */
.toggleAdditionalConditions{
  li{
    list-style-type: disc;
  }
}

.client-modal-selection{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999999;
  background-color: rgba(0,0,0,0.8);
  display: none;
  justify-content: center;
  align-items: center;

  .content{
    background-color: white;
    width: 50%;
    padding: 20px;

    .title{
      color: $blue;
      font-weight: bold;
      font-size: 18px;
    }

  }

}



