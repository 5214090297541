/* BORDERS */
.border-green {
  border: 3px solid $green;
  border-radius: 15px;
}

.border-red{
  border: 1px solid $red!important;
  border-radius: 10px!important;
}

.border-blue{
  border: 1px solid $blue!important;
  border-radius: 10px!important;
}

/* BACKGROUND COLOR */

.bgc-transparent {
  background-color: transparent;
}

.bgc-light-blue {
  background-color: $lightBlue;
}

.bgc-blue {
  background-color: $blue;
}

.bgc-white {
  background-color: $white !important;
}

.bgc-black {
  background-color: $lightBlack;
}

.bgc-grey {
  background-color: $grey;
}

.bgc-light-grey {
  background-color: $lightGrey;
}

.bgc-alt-light-grey {
  background-color: $alt2Grey;
}

.bgc-alt-light-grey--s{
  @media screen and (min-width: 960px){
    background-color: white;
  }
}

.bgc-yellow {
  background-color: $yellow;
}

.bgc-mustard {
  background-color: $mustard;
}

.bgc-light-yellow {
  background-color: rgba($yellow, 0.7);
}

.bgc-light-pink {
  background-color: $lightPink;
}

.bgc-pink {
  background-color: $pink;
}

.bgc-purple {
  background-color: $purple;
}

.bgc-red {
  background-color: $red;
}

.bgc-light-red {
  background-color: $lightRed;
}

.bgc-green {
  background-color: $green;
}

.bgc-light-green {
  background-color: $lightGreen;
}

.bgc-alt-light-green {
  background-color: $altLightGreen;
}

.bg-none {
  background: none;
}

/*****************************/


/* COLORS */

.white, .white:hover {
  color: $white;
}

.grey {
  color: $grey;
}

.altGrey {
  color: $altGrey;
}

.darkGrey {
  color: $darkGrey;
}

.black {
  color: $black;
}

.red {
  color: $red;
}

.yellow {
  color: $yellow;
}

.mustard {
  color: $mustard;
}

.blue, a.blue:hover {
  color: $blue;
}

.darkBlue {
  color: $darkBlue;
}

.green {
  color: $green;
}

.pink {
  color: $pink;
}
