.search-drink-modal-container{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0,0,0,0.8);
  display:flex;
  justify-content: center;
  align-items: center;


  .search-drink-modal{
    height: 100%;
    min-height: 30vh;
    background-color: white;
    border-radius: 20px;
    padding: 0px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .cross-div{
      text-align: right;
      margin: 10px  30px;
      #cross{
        color: $darkGrey;
        font-family: sans-serif;
        font-size: 30px;
      }
      #cross:hover{
        color: $grey;
        cursor:pointer;
      }
    }

    img {
      margin-bottom: 5px !important;
    }

    .content{
      position: relative;
      text-align: center;
      padding: 15px 30px;
      margin:0px 20px;

      .title{
        margin-bottom: 15px;
        font-size: 1.4em;
        font-weight: bold;
        text-transform: uppercase;
      }

      .description {
        margin-bottom: 25px;
      }

      p{
        font-size: 1em;
      }
      a{
        text-transform: uppercase;
        color: #707070;
        padding: 5px 15px;
      }
      button{
        text-transform: uppercase;
        font-weight: 600;
        padding: 5px 15px;
        background-color: $yellow;
        color: $black;
        border: 2px solid transparent;
        border-radius: 8px;
      }
      button:hover{
        cursor: pointer;
        //border: 2px solid $black;
        transition: all 0.3s ease;
      }

      .buttons {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        p, button {
          font-size: 1.2em;
        }
      }    
    }

  }


}

@media screen and (max-width: 700px) {

  .search-drink-modal {
    width: 80%;
  }
  .content{
    padding: 25px 0px !important;
  }

  .buttons {
    flex-direction: column-reverse!important;
    row-gap: 15px;
  }
}