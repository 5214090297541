.survey {
  main {
    padding: 30px 0;
  }
}

#survey-loader {
  display: none;
  background: url('../images/spinner.gif') center no-repeat;
  background-size: 230px;
}

.survey-round {
  width: 20px;
  height: 20px;
  margin: 2px;
  border-radius: 50%;
  background-color: $alt2Grey;
  &.active {
    background-color: $green;
  }
}

.survey-explanation {
  padding: 70px 100px;
  hr {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  p {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

.survey-explanation-warning {
  border: 1px solid #d8d8d8;
  padding: 35px 55px;
  max-width: 700px;
  box-sizing: border-box;
  margin: auto;
  h3 {
    max-width: 200px;
    margin: -47px auto 20px;
  }
}

.survey-questions {
  padding: 45px 65px 90px;
}

.survey-question-explanation {
  h4 {
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
  }
  p {
    margin-bottom: 10px;
  }
  li {
    list-style: disc inside !important;
  }
}

.survey-choice {
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  /*display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;*/
  &.active {
    border: 2px solid $green;
  }
  input {
    appearance: none;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
  }
}

.survey-response {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin: 1px;
}
