.modal-login{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,0.7);
  z-index: 10001;
  display: none;
  justify-content: center;
  align-items: center;

  .modal-login-close{
    display: flex;
    justify-content: flex-end;
    span{
      font-size: 20px;
    }
    span:hover{
      cursor: pointer;
    }
  }

  .modal-login-content{
    background-color: white;
    padding: 20px;
    border:1px solid white;
    border-radius: 10px;
  }

}


.modal-login-registration{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background-color: rgba(0,0,0,0.7);
  z-index: 100000;

  .modal-login-registration-close{
    display: flex;
    justify-content: flex-end;
    span{
      font-size: 20px;
    }
    span:hover{
      cursor: pointer;
    }
  }

  .title{
    font-size:2.5rem;
    font-weight: bold;
  }


  .form-login{
    .errorAlert{
      color: red;
      margin: 5px 0;
    }

    .role-box{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 25px;

      .label-box{
        margin-right: 25px;
      }

      .field-box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $lightGrey3;
        border-radius: 25px;
        p{
          padding: 10px 20px;
          font-weight: 600;

          &:hover{
            cursor: pointer;
          }

          &.active{
            border: 2px solid $yellow;
            border-radius: 25px;
            background-color: $white;
          }
        }
      }
    }

    .identity-box{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;
      .field-box{
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        label{
          margin-bottom: 10px;
        }

        input{
          width: 100%;
          padding: 10px 25px;
          border-radius: 25px;
          border: 1px solid $grey;
        }
      }
    }

    .email-box,.password-box{
      margin-bottom: 25px;

      .field-box{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;

        #pass-eye{
          position: absolute;
          top: 55%;
          right: 20px;

          &:hover{
            cursor: pointer;
          }
        }

        label{
          margin-bottom: 10px;
        }

        input{
          width: 100%;
          padding: 10px 25px;
          border-radius: 25px;
          border: 1px solid $grey;
        }
      }

      .info{
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        p{
          font-weight: 300;
        }
      }
    }

    .rgpd-box{
      margin-top: 25px;
      margin-bottom: 50px;

      a{
        text-decoration: underline!important;
      }
    }

    .newsletter-box{
      margin-top: 25px;
      margin-bottom: 50px;
    }

    .btn-box{
      margin-bottom: 25px;
      button{
        padding-top: 10px;
        padding-bottom: 10px;
        width: 100%;

        &:disabled{
          background-color: grey;
        }
      }
    }

    .already-client{
      a{
        text-decoration: underline!important;
      }
    }

    @media screen and (max-width: 992px){
      width: 100%;
      padding: 30px 5px;

      .title{
        font-size: 32px;
      }

      .role-box{
        flex-direction: column;
        align-items: flex-start;

        .field-box{
          p{
            padding: 10px 15px;
          }
        }
      }

      .identity-box{
        flex-direction: column;
        margin-bottom: 0;
        .field-box{
          width: 100%;
          align-items: flex-start;
          margin-bottom: 15px;
        }
      }

      .email-box, .password-box{
        margin-bottom: 15px;
      }

      .already-client{
        text-align: center;
      }

    }




  }


}






