@media screen and (max-width: 1200px) {
  .booking-ctn {
    padding: 60px 60px 60px 15%;
  }
  .menu-mobile {
    border-top: 2px solid $alt2Grey;
    border-bottom: 2px solid $alt2Grey;
  }
  .menu-mobile {
    .uk-nav-parent-icon > .uk-parent > a::after {
      position: absolute;
      right: 30px;
    }
  }

  .contact-container {
    margin: -350px auto 0;
  }
}


@media screen and (max-width: 960px) {

  /*************  COMMON  *************/

  .provider-booking, .event {
    .height-100 {
      height: auto;
    }
  }

  /************************************/



  /*************  ADMIN  *************/

  .admin-body {
    padding: 70px;
  }

  /************************************/



  /**************  HOME  **************/

  .btn-search-mobile {
    border: 4px solid $yellow;
    width: 260px;
    margin: 0 auto 30px;
    padding: 0 15px;
    line-height: 50px;
    cursor: pointer;
    z-index: 100;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.2);
  }

  #modal-search-mobile {
    padding: 45px 100px;
  }

  .home-search {
    width: 100%;
    margin: 60px 0 80px;
    padding: 0;
  }

  .section-home-3 {
    .uk-slider li {
      height: 220px;
    }
  }

  .section-home-4 {
    .slidenav-reviews {
      top: 75px;
    }
  }
  .home-reviews {
    min-height: auto !important;
    height: auto;
  }
  .home-review {
    padding: 25px 25px 35px 25px;
    box-shadow: none;
  }
  .home-review-client, .home-review-company {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
  .home-review-pic {
    margin: 10px auto 20px;
    width: 90px;
    height: 90px;
  }

  /************************************/


  /************  CONTACT  *************/

  .contact-bg-illustration {
    background: $yellow;
    height: auto;
    padding: 0 0 40px;
  }

  .contact-form {
    padding: 50px 25px;
    margin: 0 auto 50px;
    border-radius: 0;
  }

  .contact-bgc-text {
    padding: 0;
  }

  /************************************/


  /************  CONCEPT  *************/

  .concept-illustration-mobile {
    width: 100%;
    min-height: 150px;
    max-height: 300px;
  }

  .section-concept-3 {
    padding-bottom: 160px;
  }

  .concept-illustration:nth-of-type(1), .concept-illustration:nth-of-type(2) {
    bottom: -170px;
    left: 30px;
  }
  .concept-illustration:last-of-type {
    top: auto;
    right: -80px;
    bottom: -50px;
  }

  .section-concept-4 {
    padding: 60px 30px 80px;
    .uk-slider-container {
      padding-bottom: 30px;
    }
  }

  .slidenav-circle {
    bottom: -55px;
    padding: 0;
  }

  .slidenav-trait {
    content: '';
    background-color: $grey;
    width: calc(100% - 140px);
    height: 1px;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
  }

  .section-concept-5 {
    padding: 50px 30px 70px;
    .uk-slider-container {
      padding-bottom: 30px;
    }
    .uk-slider li {
      height: 220px;
    }
  }

  /************************************/



  /**************  BLOG  **************/

  .blog {
    .uk-slider {
      padding-bottom: 100px;
    }
  }

  /************************************/



  /*************  CLIENT  *************/

  .client {
    aside {
      width: 100%;
    }
    .uk-nav-parent-icon > .uk-parent > a::after {
      position: absolute;
      right: 25px;
    }
  }

  .event-show-sum {
    padding-bottom: 10px;
    svg {
      height: 30px;
    }
  }

  .event-show-slider {
    margin-bottom: 55px;
  }

  .booking-ctn {
    padding: 60px;
    height: auto;
    margin-bottom: 50px;
  }

  .chat-current {
    padding-bottom: 0;
    display: none;
  }


  /************************************/



  /***************  FAQ  **************/

  .faq {
    h3 {
      font-size: 16px;
    }
  }

  .faq-card {
    padding: 20px !important;
  }

  .faq-picto {
    margin-right: 30px;
    width: 30px;
  }

  /************************************/



  /************  PROVIDER  ************/

  .booking-sum {
    margin-bottom: 50px;
  }

  /*****************************/



  /* ETHICS */

  .ethics-bg {
    height: 550px;
  }

  .ethics-container .bgc-white {
    padding: 50px 50px 70px;
  }

  /*****************************/



  /* INSTRUCTIONS */

  .instructions-header {
    h1 {
      font-size: 34px !important;
      margin-top: 30px;
    }
  }

  /*****************************/



  /* SURVEY */

  .survey-explanation {
    padding: 50px;
  }

  .survey-questions {
    padding: 30px 25px 50px;
  }

  .survey-question-explanation {
    h4 {
      font-size: 14px;
    }
  }

  /*****************************/



  /* SEARCH */

  .search {

    .menu-mobile {
      border-top: none;
      padding: 20px 10px 30px;
    }
    .pagination {
      margin-top: 30px;
    }
  }

  .search-sum-mobile {
    border: 1px solid $grey;
  }

  .search-back {
    left: 0;
    transform: translateY(-50%);
    top: 50%;
  }

  .favourite-button {
    position: fixed;
    height: 45px;
    line-height: 45px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    width: 155px;
    span {
      text-transform: initial !important;
    }
  }

  .search-map-screen {
    height: 200px;
  }

  #search-map {
    opacity: 0;
    z-index: -1;
    height: 100vh;
    width: 100%;
    position: fixed!important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  #btn-filters-mobile, #btn-map-mobile {
    height: 40px;
    line-height: 40px;
    padding: 0 25px !important;
  }
  #btn-map-mobile{
    padding: 0!important;
  }

  #btn-filters-mobile {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }

  .search-providers-ctn {
    padding-top: 40px;
  }

  .search-providers {
    padding: 0 25px 90px;
  }

  .search-item-ctn--mobile {
    margin-left: 0;
    padding: 50px 20px 100px;
    justify-content: center;
    background-color: $lightGrey;
    .search-item {
      overflow: hidden;
      margin: 20px 7px 0 !important;
      background-color: $white;
      input{
        width: 100%;
      }
      a {
        font-weight: 500;
        padding: 0;
      }
    }
    button {
      margin-top: 20px;
    }
  }
}