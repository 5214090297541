.contact-container {
  margin: -25% auto 0;
}

.contact-bg-illustration {
  background-image: url("../images/illustrations/contact.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  background-color: $yellow;
  height: calc(100vh - 130px);
}

.contact-bgc-text {
  padding: 30% 50px 0;
}

.contact-form {
  max-width: 500px;
  box-sizing: border-box;
  margin: 0 auto 100px;
  border-radius: 50px;
  padding: 40px 50px;
}


@media screen and (max-width: 850px) {

  .contact-bgc-text {
    padding: 40% 50px 0;
  }

}