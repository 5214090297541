/* POPPINS */


@font-face {
  font-family: 'Poppins';
  font-weight: 100;
  src: url("../../fonts/Poppins-Thin.eot");
  src: url("../../fonts/Poppins-Thin.ttf") format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 200;
  src: url("../../fonts/Poppins-ExtraLight.eot");
  src: url("../../fonts/Poppins-ExtraLight.ttf") format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  src: url("../../fonts/Poppins-Light.eot");
  src: url("../../fonts/Poppins-Light.ttf") format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: url("../../fonts/Poppins-Regular.eot");
  src: url("../../fonts/Poppins-Regular.ttf") format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  src: url("../../fonts/Poppins-Medium.eot");
  src: url("../../fonts/Poppins-Medium.ttf") format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  src: url("../../fonts/Poppins-SemiBold.eot");
  src: url("../../fonts/Poppins-SemiBold.ttf") format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  src: url("../../fonts/Poppins-Bold.eot");
  src: url("../../fonts/Poppins-Bold.ttf") format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 800;
  src: url("../../fonts/Poppins-ExtraBold.eot");
  src: url("../../fonts/Poppins-ExtraBold.ttf") format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 900;
  src: url("../../fonts/Poppins-Black.eot");
  src: url("../../fonts/Poppins-Black.ttf") format('truetype');
}

/* FONT AWESOME */

@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";