

.search {
  .uk-drop {
    max-width: 800px;
  }
}

.search-item-ctn {
  padding: 20px 0px 0px 0px;
}

.search-item {
  padding: 10px 17px;
  border: 1px solid $grey;
  input {
    border: none;
    &::placeholder {
      font-size: 14px;
    }
  }
}

.search-providers-ctn {
  height: calc(100vh - 150px);
  overflow-y: scroll;
  padding-top: 0!important;
}

.search-providers {
  max-width: 1090px;
  margin: auto;
  padding: 0 40px 50px;
}

.search-result-ctn {
  padding: 30px 0;
}

.search-rating {
  background-color: #f5f5f5;
  padding: 7px 20px;
  border: 1px solid $grey;
  height: 33.24px;
  font-family: "Poppins", sans-serif !important;
  width: 220px;
}

.favourite-button {
  position: absolute;
  height: 60px;
  line-height: 60px;
  bottom: 20px;
  right: 20px;
  z-index: 400;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;

  .picto{
    display:inline-block;
    width: 28px;
    height: 28px;
    background-image: url("../images/picto/map_white.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 10px;
  }
}

.search-label {
  cursor: pointer;
  display: block;
  border: 1px solid $grey;
  border-radius: 30px;
  padding: 10px;
  margin: 0 0 10px 10px;
  font-size: 14px;
  width: max-content;
  &.active {
    background-color: $grey;
  }
  box-sizing: border-box;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
  }
}
@media (max-width: 420px) {
/*  .search-label{
    font-size: 12px;
  }
  .search-item a{
    font-size: 12px;
  }*/
  .search-item {
    padding: 0px 0px 0px 0px;
  }
}

/* DATEPICKER - jQuery-ui */

.ui-datepicker {
  padding: 20px;
  box-sizing: border-box;
}

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
  top: 0;
}

.ui-datepicker {
  border: none !important;
  border-radius: 15px;
  width: 100% !important;
  .ui-state-default {
    background-color: inherit;
    border: none;
    text-align: center;
    font-size: 16px;
  }
  .ui-state-active {
    background-color: $blue;
    border: none;
    border-radius: 20px;
    color: $white;
  }
  .ui-widget-header {
    background-color: inherit;
    border: none;
  }

  .ui-datepicker-month {
    font-weight: bold !important;
  }
  .ui-datepicker-year {
    font-weight: bold !important;
  }

  .ui-datepicker-prev span,
  .ui-datepicker-next span {
    width: 30px;
    height: 30px;
    margin: 10px;
    background-image: none !important;
  }

  .ui-datepicker-prev {
    margin-right: 20px;
    content: url("../images/arrow/arrow-prev.png");
  }

  .ui-datepicker-next {
    margin-left: 20px;
    content: url("../images/arrow/arrow-next.png");
  }
}

.search-keywords {
  border-radius: 30px;
}

.slider-search {
  height: 3px !important;
  .ui-widget-header {
    background-color: $grey;
  }
}

#search-map {
  height: calc(100vh - 150px);
  z-index: 1;
}

#close-map {
  display: none;
  z-index: 1001;
  margin: 10px;
}

.toggle-map {
  position: absolute;
  right: 1%;
  top: 17%;
  z-index: 401;
}

.ui-slider-handle {
  background-color: $black !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  margin-top: -10px;
  outline: none !important;
}

.text-slider-top {
  color: $black !important;
  margin-top: -20px;
  text-align: center;
  outline: none !important;
}
.text-slider-bottom {
  color: $black !important;
  margin-top: 30px;
  text-align: center;
  outline: none !important;
}

.form-sort {
  box-sizing: border-box;
  height: 30px;
  width: 177px;
  border: 1px solid #d8d8d8;
  border-radius: 20px;
  background-color: #f5f5f5;
  color: #2e2d2c;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 18px;
}

.favorite-modal {
  position: absolute;
  bottom: 10%;
  right: 2%;
  height: 385px;
  width: 420px;
  border-radius: 30px;
  background-color: #ffffff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  padding-top: 0 !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.favorite-modal::-webkit-scrollbar {
  display: none;
}

.favorite-header {
  padding-top: 30px;
  background-color: white;
  position: sticky;
  top: 0;
}

.pop-up-informations {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100px;
  background-color: #efd857;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.pop-up-informations p {
  height: 28px;
  width: 766px;
  color: #2e2d2c;
  font-family: Poppins;
  font-size: 1.43rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
}

.pop-up-informations button {
  height: 50px;
  width: 155px;
  border: none;
  border-radius: 10px;
  background-color: #ffffff;
  font-size: 1.125rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  margin-left: 40px;
}

.js-pagination {
  margin: 0 2px;
}
.creerUnDevisText{
  min-width: 86px;
}
.nameFavoriteModale{
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


@media (max-width: 600px) {
  .pop-up-informations p{
    font-size: 1rem;
  }
  #search-cancel{
    padding-top: 20px;
  }
  .pop-up-informations button {
    height: 30px;
    width: 100px;
    font-size: 1rem;
  }
  .search-item{
  }
  .CritereMenu{
    flex-direction: column;
    width: 100%;
    padding: 20px 0px 0px 5px;
  }
  .priceDropdown{
    min-width: 200px;
  }
  .keywordMenu{
    padding: 20px 0px 0px 0;
    offset: 40px;
  }
  .keywordMenubutton{
    font-size: 10px;
  }
}

@media screen and (min-width: 601px) and (max-width: 960px){
  #js-location{
    width: 60%;
  }
}

@media screen and (max-width: 960px) {

  /** Search mobile menu **/
  .search-mobile-filters{
    padding-top: 15px;
    padding-bottom: 20px;

    .content{
      padding: 15px!important;
      background-color: white;
      width: 80%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      border:1px solid transparent;
      border-radius: 15px;
      overflow: hidden;
      box-shadow: 0px 1.923076868057251px 7.849999904632568px 0px #0000002E;

    }

    .where-box{
      width: 40%;
      display: flex;
      align-items: center;

      .icon{
        display: inline-block;
        width: 20px;
        height: 20px;
        background-image: url("../images/picto/search.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      input{
        width:100%;
        border:1px solid transparent;
        padding-left: 15px;
        font-size: 16px;

        ::placeholder{
          color: $lightBlack;
        }
      }
    }

    .when-box{
      width:40%;
      background-color: $white;

      border:1px solid $grey;
      border-radius: 20px;
      padding: 12px 5px;
      text-align: center;
      border-left: 1px solid $alt2Grey;

      .label{
        color: $lightBlack;
        font-size: 16px;
      }

      .search-mobile-date-dropdown{
        left:0!important;
      }
    }

    div.filters-box{
      width: 10%;
      min-width: 35px;
      &:hover{
        cursor: pointer;
      }
      div.img-box#btn-filters-mobile{
        width: 100%;
        height: 20px;
        transform: translate(0,0)!important;
        padding: 0!important;
        line-height: 0;
        display: flex;
        justify-content: flex-end;
        img{
          height: 100%;
          width: auto;
        }
      }
    }
  }

  .search-mobile-filter-dropdown{
    display: none;
    width: 100%;
    background-color: white;
    padding: 5px;

    &.open{
      display: block;
    }
  }

  .search-mobile-breadcrumb{
    padding: 20px 20px 0 20px;
  }

  .search-mobile-map{
    z-index: 400;
    padding-top: 0!important;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

}


/** BUTTON IN DROPDOWN **/
.search-dropdown-submit{
    padding: 5px 15px;
    background-color: $green;
    color: white;
    border: 1px solid $green;
    border-radius: 8px;
}

.search-dropdown-reset{
  padding: 5px 15px;
  background-color: orangered;
  color: white;
  border: 1px solid orangered;
  border-radius: 8px;
}


/** MAP **/

.leaflet-popup-content{
  width: 151px!important;
  overflow: hidden;
}



/** Text seo **/
.search-seo-container{
  a{
    color: blue;
    text-decoration: underline;
  }
}



/** Card Empty **/
.card-empty{

  //background-color: $lightGrey;
  .content{
    height: 100%;
    border-radius: 20px;
    background-color: $yellow;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &>div{
      margin-bottom: 25px;
    }


    .img-box{
      width: 50px;
      img{
        width: 100%;
        height: auto;
      }
    }

    .title-box{
      width: 80%;
      min-width: 200px;
      p{
        font-weight: bold;
        text-align: center;
        color: $darkGrey;
      }
    }

    .text-box{
      width: 85%;
      min-width: 200px;
      p{
        text-align: center;
      }
    }

    .r-kit-btn-white{
      text-transform: uppercase;
      font-weight: 600;
      padding: 5px 15px;
      background-color: $white;
      color: $black;
      border: 1px solid transparent;
      border-radius: 8px;
      font-size: 16px;
      cursor: pointer;

      @media screen and (max-width: 992px){
        font-size: 15px;
      }
    }

  }

}

/**** PRICE DOWN *****/

.priceDropdown{

  .row{
    .item{
      border:3px solid $lightGrey;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      &:hover{
        cursor: pointer;
      }
      &.selected{
        background-color: $lightGrey;
      }
    }
  }
}


/***** KEYWORDS  *****/
.keyword-button{
  &:hover{
    background-color: $lightGrey;
  }
}
.keywordMenu{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,0.8);
  z-index: 9999999999;
  justify-content: center;
  align-items: center;

  .content{
    width: 60%;
    background-color: white;
    padding: 30px;
    border-radius: 30px;
    @media screen and (max-width: 600px){
      width: 100%;
    }

    .close-btn{
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $grey;
      color: white;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      &:hover{
        cursor: pointer;
      }
    }

    .list{

      height: 80vh;
      overflow-y: scroll;
    }
  }
}