@media screen and (max-width: 640px){
  // top page rating
  .responsive-provider-profile-rating{
    margin-bottom: 30px;
    div{
      width: 50%;
    }

    .backlink{
      display: flex;
      align-items: center;
      width: 45%;
      a{
        font-size: 30px;
        color: #2E2DCD;
      }

    }

    .rating{
      span{
        width: 50px;
        height: 50px;
        border-radius: 25px;
        padding: 10px;
        //background-color: #1EA7AC;
        color: white;
        font-weight: 700;
        font-size: 22px;
      }
    }


  }

  .responsive-provider-profile-rating-desktop{
    display: none;
  }

  // form
  #formSelection .uk-grid.uk-grid-stack{
    margin-top: 0!important;
  }


  // top name
  .responsive-provider-profile-name{
    width: 100%;
    h1{
      text-align: center;
      font-weight: 600;
      font-size: 22px;
    }
    address{
      text-align: center;
      margin: 10px 0 15px!important;
    }
  }

  // top pictures
  .responsive-provider-profile-img-box{
    ul{
      border-radius: 10px;
      overflow: hidden;
    }
  }

  // like
  .responsive-provider-profile-like-box{
    position: absolute;
    top: 120px;
    right: 20px;
    z-index: 1;
    width: 40px;
    height: 40px;
    &>*{
      width: 100%;
      height: 100%;
      margin:0;
    }
  }


  // description
  .responsive-provider-profile-h2-title{
    display: flex;
    justify-content: flex-start;
    align-items: center;

    div{
      width: 15px;
      margin: 0 10px;
      img{
        width: 100%;
        height: auto;
      }

      .hidden{
        display: none;
      }
      .show{
        display: block;
      }
    }


  }

  // .responsive-provider-profile-description-text{
  //   display: none;
  // }


  // original aside "A savoir"
  // .responsive-provider-profile-orginial-aside{
  //   display: none;
  // }

  // prestations
  #prestations{
    padding-top:0;
  }

  .responsive-provider-profile-prestation-container{
    display: none;

    .product{
      .row{
        flex-direction: column;
        text-align: center;

        .price{
          display: none;
        }

        .carousel{
          width: 85%;
          margin: 0 auto;
        }
      }
    }


  }

  .responsive-price{
    display: block!important;
    margin-bottom: 30px;
  }

  .responsive-description{
    width: 85%;
    margin: 0 auto;
  }

  // services
  .responsive-provider-profile-services-text{
    display: none;
  }

  // infos
  .responsive-provider-profile-infos-text{
    display: none;
  }

  // dispos
  .responsive-provider-profile-dispos-text{
    display: none;
    h2{
      display: none;
    }
  }


  // rating
  .responsive-provider-profile-rating-text{
    display: none;
    h2{
      display: none;
    }

    #avis{
      margin-top: 0;
    }

    .responsive-reviews{
      flex-direction: column;

      h5{
        text-align: center;
      }

      .responsive-review{
        justify-content: flex-start;
        flex-wrap: wrap;
        span{
          display: inline-block;
          width: 100%;

        }
      }
    }

    .responsive-reviews-modal{
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999;
      background-color: white;
      width: 100vw;
      height: 100vh;
      padding: 10px;
      overflow-y: scroll;

      .responsive-reviews-reviews{
        display: block;
        .review-user{
          margin: 0;
        }
      }

      .responsive-reviews-response{
        margin: 0;
      }

      .responsive-reviews-close{
        text-align: right;
      }

    }


    .responsive-reviews-more{
      display: block;
      text-align: center;
      p{
        text-decoration: underline;
      }
    }
  }


}

.responsive-price{
  display: none;
}

.responsive-reviews-more{
  display: none;
}