.topEncart{
    background: $yellow;
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 20px;

    .title{
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 10px;
    }

    ul{
        padding-top: 5px;
        padding-left: 5px;

        li{
            display: block;

            &:before{
                content: "• ";
            }

            a{
                display: inline-block;
                text-decoration: underline;
            }
        }
    }
}

.btnSee{
    img{
        display: inline-block;
        margin-right: 5px;
    }
    &.button{
        width: 100%;
        margin-bottom: 10px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
        border-radius: 8px;
        border: 2px solid $yellow;
        padding: 10px 20px;
        cursor: pointer;
        color: black;
        transition: .3s ease;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 20px;

        img{
            display: inline-block;
            margin-left: 10px;

        }

        &:hover{
            background: $yellow;
            transition: .3s ease;
        }

        &.bgYellow{
            background: $yellow;

            &:hover{
                background: white;
                color: black;
            }
        }

        &.red{
            color: $red;
            border-color: $red;

            &:hover{
                background: $red;
                color: white;
            }
        }
    }
}

.imgTitle{
    width: 15px;
    margin-left: 2px;
}

.modalMore-assurance{
    text-align: center;

    .inner{
        max-width: 700px;
    }

    .assuranceTitle{
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: $red;
        margin-bottom: 30px;
    }

    .red{
        color: $red;
    }

    .fileFormInner {
        display: inline-block;
        position: relative;
        
        input[type=file] {
            cursor: pointer;
            height: 100%;
            width: 100%;
            position: absolute;
            opacity: 0;
            top: 0;
            left: 0;

            &.error {
                & ~ .dropzone {
                    border-color: red;
                }
                & ~ .msg-error {
                    display: block;
                }
            }
            &.valid {
                & + .dropzone {
                    border-color: green;
                }
            }
        }   

        .dropzone{
            .button{
                margin-top: 20px;
            }
        }

        .dropzoneInner {
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='26' stroke-linecap='square'/%3e%3c/svg%3e");
            border-radius: 8px; 
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 400px;
            height: 130px;
            user-select: none;
            margin-top: 20px;
        }
        
        .error-msg {
            position: absolute;
            top: 100%;
            text-align: left;
            white-space: nowrap;
            left: 0;
        }
        
        .btn-remove {
            background: none;
            border: none;
            display: none;
            font-weight: 800;
            margin-left: 10px;
            cursor: pointer;
    
            &:before {
                content: 'X';
            }
        }
       
        &.dragover .dropzoneInner {
           background: aliceblue; 
        }

        .msg{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: $grey;

            img{
                margin-bottom: 5px;
                width: 30px;
            }
        }
        
        &.hasFile {
            .msg {
                display: none;
            }
            .btn-remove {
                display: inline-flex;
            }
            input[type=file] {
                display: none;
            }
        }
        
        .loader {
            border: 1px solid rgba(0, 0, 30, 0.2);
            border-radius: 6px;
            display: none;
            align-items: center;
            justify-content: space-between;
            font-size: 1.4rem;
            padding: 20px;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            background: #fff;
    
            .progress {
                position: absolute;
                height: 100%;
                left: 0;
                top: 0;
                background: rgba(120, 190, 32, 0.2);
                width: 0;
                transition: width 0.3s linear;
            }
        }
        
        &.loading .loader {
            display: flex;
        }
    }
}

.buttonAssurance{
    margin-top: 25px;

    img{
        width: 15px;
    }
}



.jauge{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100px;
    overflow: hidden;

    .progressionBar{
        width: 125px;
        height: 125px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0 0;
        
        .back{
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 100%;
          border-top: 15px solid #F5F5F5;
          border-right: 15px solid #F5F5F5;
          border-left: 15px solid transparent;
          border-bottom: 15px solid transparent;
          transform: rotate(-45deg);
        }
        .prog{
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 100%;
          border-top: 15px solid #EFD857;
          border-right: 15px solid #EFD857;
          border-left: 15px solid transparent;
          border-bottom: 15px solid transparent;
          transform: rotate(-225deg);
          transition: 1s ease;
        }
        .cache{
          position: absolute;
          top: 50%;
          bottom: -15px;
          left: -15px;
          right: -15px;
          background: white;
        }
        .number{
          position: relative;
          z-index: 2;
          font-size: 32px;
          line-height: 28px;
          font-family: sans-serif;
          transform: translateY(-50%);
          font-weight: 600;
        }
    }
}

.profilCompletionContainer{
    padding: 30px;

    overflow: hidden;
    height: 130px;

    &.open{
        height: inherit;

        img{
            transform: scaleY(-1)
        }
    }

    .titleProfil{
        cursor: pointer;

        img{
            width: 20px;
            margin-left: 10px;
        }
    }

    .recap{
        ul{
            margin-top: 30px;

            li{
                position: relative;
                padding-left: 30px;
                margin-bottom: 8px;

                &:before{
                    position: absolute;
                    content:'';
                    top: 3px;
                    left: 0;
                    display: block;
                    width: 16px;
                    height: 16px;
                    border-radius: 20px;
                    border: 2px solid $red;
                }

                &.active{
                    &:before{
                        border-color: black;
                    }

                    &:after{
                        content: "";
                        display: block;
                        position: absolute;
                        left: 7px;
                        top: 7px;
                        border: solid black;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                        width: 5px;
                        height: 8px;
                    }
                }
            
            }
        }
    }
}