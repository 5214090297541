/* SLIDER */

.slidenav-pink {
  &.uk-slidenav, &.uk-slidenav:focus {
    color: $pink;
  }
  &.uk-slidenav:hover {
    color: $darkPink;
  }
}

.slidenav-green {
  &.uk-slidenav, &.uk-slidenav:focus {
    color: $green;
  }
  &.uk-slidenav:hover {
    color: $darkGreen;
  }
}

.slidenav-blue {
  &.uk-slidenav, &.uk-slidenav:focus {
    color: $blue;
  }
  &.uk-slidenav:hover {
    color: $altBlue;
  }
}

.slidenav-yellow {
  &.uk-slidenav, &.uk-slidenav:focus {
    color: $yellowAlt;
  }
  &.uk-slidenav:hover {
    color: $yellow;
  }
}

.slidenav-black {
  &.uk-slidenav, &.uk-slidenav:focus {
    color: $black;
  }
}

.slidenav-small svg {
  height: 20px;
}
