/* ACCOUNT */

#modal-reviews .uk-modal-dialog {
  width: 100% !important;
  overflow: scroll;
  > *,
  th {
    font-size: 13px !important;
  }
}
.img-webinaire{
  width: 130px;
}
.img-webinaire img{
  width: 100%;
}
.img-advice{
  width: 150px;
  height: 150px;
  overflow: hidden;

}
.text-content-advice{
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-advice{
  width: 660px;
  margin-right: 10px;

}
@media screen and (max-width: 1200px) {
  .text-advice {
    width: 600px;
  }
}
@media screen and (max-width: 1100px) {
  .text-advice {
    width: 530px;
  }
}
@media screen and (max-width: 1000px) {
  .text-advice {
    width: 400px;
  }
}
@media screen and (max-width: 9000px) {
  .text-advice {
    width: 100%;
  }
}
.text-shortdescribe{
  text-align: center;
}

.img-partner{
  width: 80%;
  height: 100px;
/*  width: 80%;
  max-height: 150px;
  overflow: hidden;*/
}

.img-partner img{
  width: 100%;
}
.partner-text{
  overflow: hidden;
  text-overflow: ellipsis;
}
.w-65{
  width: 150px;
}
.acces-webinaire{
  background-color: #efd857;
  color: #FFFFFF;
}
.card-partner{
  width: 34%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cards-detail-partner{
  width: 28%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 640px){
    width: 100%;
    .partner-text{
      height: initial!important;
    }
  }
}
.search-green{
  overflow: hidden;
}
.search-green input{
  float: right;
  clear: both;
}
.partner-mail{
  display: table;
  width: 100%;text-align:left;
}
.partner-mail span{
  vertical-align: middle;
  display: table-cell;
}
.provider-account-menu,
.search-menu {
  a {
    margin: 0 15px;
    &:after {
      width: 100%;
      height: 5px;
      position: absolute;
      display: block;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 3px;
      background-color: $yellow;
    }
  }
}
@media screen and (max-width: 600px) {

  .provider-account-menu a{
    margin: 0 5px;
  }
}
.provider-account-menu {
  a.active,
  a:hover {
    &:after {
      content: "";
    }
  }
}

.search-menu {
  .place:hover,
  .underline-green:hover,
  .underline-green.active {
    &::after {
      content: "";
      background-color: $green;
    }
  }

  .tent:hover,
  .underline-red:hover,
  .underline-red.active {
    &::after {
      content: "";
      background-color: $red;
    }
  }

  .service:hover,
  .underline-yellow:hover,
  .underline-yellow.active {
    &::after {
      content: "";
      background-color: $yellow;
    }
  }

  .food:hover,
  .underline-blue:hover,
  .underline-blue.active {
    &::after {
      content: "";
      background-color: $blue;
    }
  }

  .drink:hover,
  .underline-purple:hover,
  .underline-purple.active {
    &::after {
      content: "";
      background-color: $purple;
    }
  }
  .tent:hover,
  .underline-red:hover,
  .underline-red.active {
    &::after {
      content: "";
      background-color: $red;
    }
  }

  .decoration:hover,
  .underline-pink:hover,
  .underline-pink.active {
    &::after {
      content: "";
      background-color: $pink;
    }
  }
}

.rating input {
  -webkit-appearance: none;
}

.input-rating {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 2.5px;
}

.input-rating--empty {
  background-color: $grey;
}

.input-rating--full {
  background-color: $blue;
}

.review-block {
  padding: 30px;
  max-width: 550px;
  margin: 30px auto;
  background-color: rgba($lightBlue, 0.2);
  border: 2px solid $black;
  label {
    font-size: 13px;
    color: $black;
  }
}

/*****************************/

/* PROVIDER FORMS */

.js-new-pic-overview {
  display: none;
  width: 100px;
  max-height: 80px;
  margin-right: 30px;
}

.error-upload-file {
  display: none;
}

.button.input-caracteristic{
  margin: 10px 5px;
  color: $black;
  padding: 5px 15px;
  line-height: 30px;

  &.checkbox{
    background-color: $white;
    border: 2px solid $white;
    border-radius: 30px;
  }

  .labelTxt{
    display: block;
  }

  input[type="checkbox"] {
    opacity: 0;
    position: fixed;
    width: 0;
    display: none;
  }

  input[type="number"],input[type="text"]{
    border: none;
    background: none;
    outline-style: none;
    height: 50px;
    border-radius: 8px;
    padding: 0px 17px;
    font-size: 14px;
    background: white;
    line-height: 21px;
    box-shadow: none;
  }

  &.active {
    border: 2px solid $yellow;
  }
}

.button.input-tag {
  background-color: $white;
  border: 2px solid $white;
  margin: 10px 5px;
  color: $black;
  padding: 5px 15px;
  line-height: 30px;
  /*&.input-tag--white {
    background-color: $white;
    border: 2px solid $white;
  }*/
  &.active {
    border: 2px solid $yellow;
  }
  input {
    opacity: 0;
    position: fixed;
    width: 0;
    display: none;
  }
}

.provider-account-img {
  width: 35px;
}

.uk-divider-vertical.provider-divider-vertical {
  height: 70px;
  margin: auto 15px !important;
}

.product-table th.yellow {
  font-size: 16px;
  font-weight: 600;
}

.provider-profile-success {
  p {
    color: $green;
  }
  svg {
    fill: $green;
  }
}
.provider-profile-fail {
  p {
    color: $red;
  }
  svg {
    fill: $red;
  }
}
.provider-profile-warning {
  p {
    color: $mustard;
  }
  svg {
    fill: $mustard;
  }
}

#form-warning-place {
  display: none;
}
.green-search{
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
}
//Modal
.modal-green-event {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content-green-event {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.close-green-event {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-green-event:hover,
.close-green-event:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}