p {
  margin-top: 0 !important;
  margin-bottom: 0;
  font-weight: 400;
}

.font-10 {
  font-size: 10px;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px!important;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

.font-26 {
  font-size: 26px;
}

.font-32 {
  font-size: 32px;
}

.font-36 {
  font-size: 36px;
}

.font-40 {
  font-size: 40px;
}

.font-100 {
  font-size: 100px;
}

b {
  font-weight: 600;
}
.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

.italic {
  font-style: italic;
}

.link-hover:hover {
  color: $black;
}

.line-height-1 {
  line-height: 1;
}

.line-height-2 {
  line-height: 2.5;
}

.line-height-40 {
  line-height: 40px !important;
}

.line-height-50 {
  line-height: 50px !important;
}

.height-25 {
  height: 25px !important;
}
.height-50 {
  height: 50px !important;
}
.min-height-50{
  min-height: 50px !important;
}
.height-110 {
  height: 110px !important;
}
.min-height-110{
  min-height: 110px !important;
}
.w-50 {
  width: 50px;
}

.underline {
  text-decoration: underline;
}

.letter-spacing-s {
  letter-spacing: 1px;
}

.letter-spacing-m {
  letter-spacing: 2px;
}

.letter-spacing-l {
  letter-spacing: 3px;
}