
.concept-partners-ctn {
  overflow: hidden;
  padding: 40px 100px;
  border-radius: 40px;
  img {
    max-width: 180px;
    max-height: 90px;
  }
  .uk-position-small {
    margin: 15px 30px;
  }
}

.section-concept-1 {
  background-color: $yellow;
  padding-bottom: 150px;
}

.concept-bg-illustration {
  background-image: url("../images/illustrations/concept.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;

  &>.container{
    max-width: 1200px;
  }
}

.section-concept-1-text {
  padding: 30% 50px 0;
}

.section-concept-2 {
  padding-bottom: 120px;
}

.concept-2-card {
  margin-top: -105px;
}

.section-concept-3 {
  /*.container {
    padding: 60px 0;
  }*/

  .container {
    padding: 50px 60px 130px;
  }
  //overflow: hidden;
  .identity {
    right: -50px;
    top: -60px;
    width: 80px;
  }
}

.concept-illustration {
  z-index: 10;
  &:first-of-type {
    bottom: 40px;
    left: 50px;
    height: 220px;
  }
  &:last-of-type {
    top: 10px;
    right: -65px;
    height: 180px;
  }
}

.concept-card {
  padding: 50px;
}

.section-concept-4 {
  padding: 100px 0;
}

.concept-picto-ctn {
  max-width: 250px;
}

.concept-picto {
  height: 100px;
  margin: 0 auto 25px;
  img {
    max-width: 100px;
    height: 100%;
  }
}

.section-concept-5 {
  padding: 90px 0 70px 0;
}

.section-concept-6 {
  padding: 70px 0 85px;
}