.modal-blog-newsletter{
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0,0,0,0.8);
  z-index: 99999;
  display: none;
  justify-content: center;
  align-items: center;

  .content{
    width: 40%;
    max-height: 100vh;
    overflow-y: scroll;
    @media screen and (max-width: 992px){
      width: 90%;
    }
    .top{
      background-image: url('../images/blog/modal-newsletter.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 30vh;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      position: relative;
      padding-bottom: 30px;

      .close{
        position: absolute;
        top: 10px;
        right: 10px;
        color: $white;
        font-size: 15px;
        font-weight: bold;
        z-index: 1;
        &:hover{
          cursor: pointer;
        }
      }

      .filter{
        background-color: rgba(0,0,0,0.7);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
      }

      p{
        position: relative;
        z-index: 1;
        color: $lightYellow;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: bold;
        text-align: center;
        width: 40%;
        margin: 10px auto;
      }
    }

    .form{
      background-color: white;
      .r-block-subscription{
        min-height: 25vh;
        box-shadow: 1px 5px 8px #2E2D2C26;
        padding: 20px;
        border-radius: 8px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        .title{
          font-weight: bold;
          font-size: 15px;
          color: $black;
          margin: 15px 0;
          span{
            background-color: $blue;
            color: white;
          }
        }

        .discover{
          margin: 20px 0;
          color: $black;
          font-size: 15px;
          span{
            background-color: $blue;
            color: white;
          }
        }

        form{
          width: 80%;
          margin: 0 auto;
          .r-input{
            margin-bottom: 20px;
            input{
              width: 100%;
              padding: 10px 15px;
              background-color: $lightGrey;
              color: $black;
              border:1px solid transparent;
              border-radius: 43px;
              font-size: 13px;
            }
            input::placeholder{
              color: $black;
            }
          }

          .r-radio{
            margin-bottom: 20px;
            .r-visual{
              width: 100%;
              color: $black;
              //font-size: 20px;
              font-size: 13px;
              font-weight: bold;
              overflow: hidden;
              div{
                width: 50%;
                padding: 10px;
                border:1px solid $lightGrey;
                text-align: center;

                &.individual{
                  border-top-left-radius: 39px;
                  border-bottom-left-radius: 39px;
                }
                &.society{
                  border-top-right-radius: 39px;
                  border-bottom-right-radius: 39px;
                }

                &:hover{
                  cursor: pointer;
                }
              }
              .active{
                background-color: $lightGrey;
              }
            }
          }

          .r-btn{
            button{
              text-transform: uppercase;
              color: $black;
              background-color: $grey;
              font-weight: bold;
              border:1px solid transparent;
              width: 85%;
            }
          }
        }

        .promise{
          width: 100%;
          margin-top: 20px;
          text-align: center;
          color: $black;
          font-size: 13px;
          font-weight: 300;
        }
      }
    }
  }


}