/* FORM */

#article_hours_hour, #article_hours_minute {
  width: 60px;
  height: 40px;
  padding: 0 10px;
  margin: 10px
}

#article_publishedAt_date {
  height: 40px;
  vertical-align: middle;
  display: inline-block;
  max-width: 100%;
  width: 100%;
  padding: 0 10px;
  background: #fff;
  color: #666;
  border: 1px solid #e5e5e5;
  transition: 0.2s ease-in-out;
  transition-property: all;
  /*transition-property: color, background-color, border;*/
  margin: 10px 10px 10px 0;
  font-size: 14px;
}

#article_publishedAt_time {
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  margin: 10px 0;
  font-size: 14px;
  select {
    height: 40px;
    padding: 0 10px;
    border: 1px solid #e5e5e5;
    border-radius: 0;
    margin: 0 3px;
  }
}

/***********************************/



/* BLOG */

.blog {
  main {
    background-color: $lightGreen;
    padding: 60px 0;
  }
  aside:not(:first-of-type) {
    padding: 25px 35px;
  }
}

.pagination {
  > * {
    font-size: 14px;
    font-weight: 600;
    color: $black;
  }
  &.green {
    .next, .previous {
      color: $green;
    }
  }
  &.yellow {
    .next, .previous {
      color: $yellow;
    }
  }
  &.blue {
    .next, .previous {
      color: $blue;
    }
  }
  &.pink {
    .next, .previous {
      color: $pink;
    }
  }
  .next {
    margin-left: 5px;
  }
  .previous {
    margin-right: 5px;
  }
  .last, .first {
    display: none;
  }
  .page {
    margin: 0 2px;
  }
  .current {
    font-weight: 600;
    font-size: 18px;
    margin: 0 4px;
  }
}

.blog-menu {
  a {
    color: $lightBlack;
  }
  a.active, a:hover, a:active {
    color: $green;
    border-bottom: 1px solid $green;
    padding-bottom: 1px;
    font-weight: 400;
  }
  li:not(:last-of-type):after {
    content: '|';
    font-size: 20px;
    font-weight: 100;
    color: $grey;
    display: inline-block;
    margin: 0 10px;
  }
}

.blog-article {
  height: 270px;
  width: 100%;
}

.blog-article:nth-child(odd) {
  .article-share {
    right: 40px;
  }
}

.blog-article:nth-child(even) {
  display: flex;
  flex-direction: row-reverse;
  .article-share {
    left: 40px;
  }
}

.article-share {
  bottom: 20px;
  svg {
    height: 16px;
  }
}

.blog-article-sum {
  padding: 20px 20px 20px 40px;
}

.blog-search {
  border-radius: 25px;
  height: 50px;
  line-height: 50px;
  width: 100%;
  .uk-search-input {
    border: none;
    padding-left: 55px !important;
    font-weight: 300;
  }
  .uk-search-icon {
    width: 60px;
  }
  svg {
    width: 25px;
  }
}


.newsletter-form {
  input, input:focus  {
    background-color: transparent;
    border: none;
  }
  button {
    line-height: 40px;
    border: none;
    background-color: transparent;
  }
}

.newsletter-form-ctn {
  background-color: #F5F5F5;
  border-radius: 25px;
  box-sizing: border-box;
  margin-left: -15px !important;
  width: calc(100% + 30px);
  margin-top: 20px;
  padding: 0 20px;
}


.last-articles-pagination {
  img {
    width: 20px;
  }
}

.blog-article-show {
  padding: 30px 50px;
  *{
    font-family: "Poppins", sans-serif!important;
  }
  p {
    text-align: justify;
    display: inline-block;
    width: 100%;
  }
  h2 {
    margin-bottom: 20px;
    & *{
      font-weight: bold!important;
      font-size: 16px!important;
    }
  }
  a {
    text-decoration: underline;
  }
  ul {
    margin: 5px 0;
  }
  li {
    list-style: disc inside;
    p {
      display: inline;
    }
  }
  img {
    display: block;
    width: auto !important;
    height: auto !important;
    margin: 30px auto;
  }
}

.blog-article-img {
  width: 100%;
  height: 350px;
}

.article-card-img {
  height: 170px;
}

.last-articles {
  li {
    display: none;
  }
}

.article-editor .cke_contents {
  height: 800px !important;
}

/** MODAL **/
.blog-article-show-modal{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 50;
  display: none;
  justify-content: center;
  align-items: center;

  .cross-box{
    width: 100%;
    margin-bottom: 10px;
    .cross{
      font-size: 1.6em;
      color: $black;
    }
    .cross:hover{
      cursor: pointer;
    }
  }



  .content{
    max-height: 70vh;
    overflow-y: scroll;
    padding: 30px;
    background-color: $yellow;
    width: 40%;
    min-width: 630px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .title-box{
      text-align: center;
      margin-bottom: 15px;
      .title{
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 10px;
      }
      .subtitle{
        font-style: italic;
      }
    }


    .form{
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      input[type="text"],input[type="email"]{
        width: 100%;
        border:1px solid transparent;
        padding: 15px 10px;
        border-radius: 20px;
        text-align: center;
        margin-bottom: 10px;
        -webkit-box-shadow: 9px 8px 18px -6px rgba(0,0,0,0.69);
        -moz-box-shadow: 9px 8px 18px -6px rgba(0,0,0,0.69);
        box-shadow: 9px 8px 18px -6px rgba(0,0,0,0.69);
      }
      input::placeholder{
        color: $darkGrey;
        text-transform: uppercase;
      }
      .checkbox-box{
        display: flex;
        align-items: center;
        input[type="checkbox"]{
          height: 25px;
          width: 25px;
          border:1px solid $grey;
          border-radius: 2px;
          margin-right: 10px;
          &:checked{
            -webkit-appearance: button;
            height: 25px!important;
            width: 25px!important;
          }
        }
        a{
          color: $black;
          text-decoration: underline;
        }
      }

      .btn-box{
        margin-top: 10px;
        width: 100%;
        button{
          &:hover{
            cursor: pointer;
          }
          width: 100%;
          background-color: $yellow;
          color: white;
          font-weight: bold;
          font-size: 20px;
          text-transform: uppercase;
          border:3px solid white;
          border-radius: 20px;
          padding: 10px;
          -webkit-box-shadow: 9px 8px 18px -6px rgba(0,0,0,0.69);
          -moz-box-shadow: 9px 8px 18px -6px rgba(0,0,0,0.69);
          box-shadow: 9px 8px 18px -6px rgba(0,0,0,0.69);
        }
      }

    }

  }

  @media screen and (max-width: 992px){
    overflow-y: scroll;
    .content{
      min-width: initial;
      width: 100%;

      .form{
        width: 100%;

        .checkbox-box{
          margin: 10px 0;
        }
      }
    }
  }
  
}


























