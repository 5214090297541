/******************************************
     *      _modal_add_picture2.html.twig  *
     ******************************************/
.drop-label{
  height: 40vh;
  overflow-y: scroll;
}

.fromjs-input{
  height: 50px;
}

/*
.fromjs-remove{
  &:hover{
    cursor: pointer;
  }
}
*/

#file-label label{
  &:hover{
    cursor: pointer;
  }
}