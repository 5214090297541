$black: #302F30;
$black2: #1A1A1A;
$lightBlack: #2E2D2C;
$white: #fff;
$grey: #d8d8d8;
$grey2: #707070;
$grey3: #C1C0C0;
$lightGrey: #f5f5f5;

$lightGrey3: #F5F5F5;
$altGrey: #b0b0b0;
$alt2Grey: #efefef;
$darkGrey: #302F30;
$blue: #1ea7ac;
$lightBlue: rgba($blue, 0.3);
$lightBlue2: rgba(28, 162, 166, 0.1);
$altBlue: #198f94;
$yellow: #efd857;
$yellowAlt: #e5db3f;
$lightYellow: #fff8d1;
$pink: #eebcd8;
$lightPink: #F6EFF3;
$darkPink: #e2479e;
$mustard: #f2a106;
$orange: #f27406;
$red: #e74011;
$lightRed: #ffa185;
$altRed: #d13a0f;
$green: #6cbe99;
$darkGreen: #55ba8d;
$lightGreen: #e5f7f0;
$altLightGreen: #9adfbf;
$darkBlue: #082933;
$purple: #3c4488;
$aqua: #1EA7AC;
