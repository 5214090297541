.product-edit-modal2-container{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0,0,0,0.8);
  display:none;
  justify-content: center;
  align-items: center;


  .product-edit-modal2{
    width: 80%;
    min-height: 30vh;
    max-height: 90vh;
    overflow-y: scroll;
    background-color: white;
    border-radius: 20px;
    padding-bottom: 10vh;

    .cross-div{
      text-align: right;
      padding: 10px  30px;
      #cross{
        color: $darkGrey;
        font-family: sans-serif;
        font-size: 30px;
      }
      #cross:hover{
        color: $grey;
        cursor:pointer;
      }
    }

    .title{
      font-size: 1.6em;
      text-align: center;
      font-weight: bold;
    }

    #js-product-modal2-content{
      padding: 20px;

      .subtitle{
        font-size: 1.4em;
        font-weight: bold;
        margin: 10px 0 20px;
      }

      .result-line{
        margin: 10px 0;
        p{
          font-size: 1.2em;
        }
        p:hover{
          cursor: pointer;
          color: $yellowAlt;
        }
      }

    }

  }


}