@media screen and (max-width: 640px){

  .responsive-calendar-container{
    width: 100%;
    #calendar{
      width: 100%;
    }
    #calendar>.fc-header-toolbar{
      flex-direction: column;
      width: 100%;
    }

    .fc-daygrid-body{
      width: 100%!important;
    }
    table{
      width: 100%!important;
    }
  }


  .responsive-calendarProvider-dateChoice{
    width: 100%;
  }

}