@import '../../node_modules/@fullcalendar/common/main.css';
@import '../../node_modules/@fullcalendar/daygrid/main.css';


/* CALENDAR */

#calendar {
  background-color: $white;
  border-radius: 20px;
  padding: 15px;
}

.fc-h-event.unavailabilityEvent {
    height: 25px;
    border: 1px solid $black;
    border: 1px solid var(--fc-event-border-color, $black);
    border-radius: 30px;
    background-color: $black;
    background-color: var(--fc-event-bg-color, $black);
    padding: 0 10px;
    font-size: 12px;
}

.fc-h-event.selectionEventPlanned {
    height: 25px;
    border: 1px solid $yellow;
    border: 1px solid var(--fc-event-border-color, $yellow);
    border-radius: 30px;
    background-color: $yellow;
    background-color: var(--fc-event-bg-color, $yellow);
    padding: 0 10px;
    font-size: 12px;
}

.fc-h-event.selectionEvent {
    height: 25px;
    border: 1px solid $grey;
    border: 1px solid var(--fc-event-border-color, $grey);
    border-radius: 30px;
    background-color: $grey;
    background-color: var(--fc-event-bg-color, $grey);
    padding: 0 10px;
    font-size: 12px;
}

.unavailabilityEvent {
    .fc-daygrid-event-dot{
        border: 5px solid $black;
    }
}

.selectionEvent {
    .fc-daygrid-event-dot{
        border: 5px solid $yellow;
    }
}

.selectionEventPlanned {
    .fc-daygrid-event-dot{
        border: 5px solid $grey;
    }
}


.fc-icon-prev{
    background-image: url("../images/arrow/prev.svg");
    background-size: contain;
    background-repeat: no-repeat;
}

.fc-icon-next{
    background-image: url("../images/arrow/next.svg");
    background-size: contain;
    background-repeat: no-repeat;
}


/* FORMS UNAVAILABILITY */

#startDate_date{
    display: flex;
}

#startDate_date_day{
    order: 1;
}

#startDate_date_month{
    order: 2;
}

#startDate_date_year{
    order: 3;
}

#endDate_date{
    display: flex;
}

#endDate_date_day{
    order: 1;
}

#endDate_date_month{
    order: 2;
}

#endDate_date_year{
    order: 3;
}

#startDate_date_month, #startDate_date_day, #startDate_date_year, #endDate_date_month, #endDate_date_day, #endDate_date_year {
    border-radius: 30px;
    border: solid 1px $grey;
    font-weight: 200;
    padding: 5px 20px;
    font-size: 14px;
    margin: 0 5px;
    &.form-field-xsmall {
        padding: 0 10px;
    }
}

#startDate_time_hour, #startDate_time_minute, #endDate_time_hour, #endDate_time_minute {
    border-radius: 30px;
    border: solid 1px $grey;
    font-weight: 200;
    padding: 5px 20px;
    font-size: 14px;
    margin: 4px 5px;
    &.form-field-xsmall {
        padding: 0 10px;
    }
}
