/* ADMIN */

.admin-nav {
  height: 100vh;
  overflow-x: scroll;
  width: 250px;
  background-color: $darkBlue;
  li, a {
    color: $white !important;
  }
  ul {
    list-style: none;
    font-size: 14px;
  }
  hr {
    margin: 10px 0;
    border-top: 1px solid $white;
  }
}


.admin-body {
  padding: 70px 0;
  margin: auto;
}

.admin-scroll {
  overflow-y: scroll;
  height: 100vh;
  box-sizing: border-box;
  background-color: $lightGrey;
  padding-left: 20px!important;
  padding-right: 20px!important;
}

.admin-title-notification {
  padding: 10px 20px;
}
.arrow-up{
  width: 20px;
  height: 40px;
  color: grey;
}

.admin-notification {
  padding: 10px 20px;
  margin-bottom: 20px;
}

//switch bouton toggle form
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.switch-text{
  font-weight: 500;
  margin-right: 50px;
}
