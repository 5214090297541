@import url('https://fonts.googleapis.com/icon?family=Material+Icons');


.input-container {
  display: flex;
  align-items: center;
  border: 2px solid $grey;
  border-radius: 30px;
  font-weight: 200;
  padding: 0 20px;
  font-size: 14px;
  background-color: rgb(232, 240, 254);

}
.titre-burger{
  border-bottom: 5px solid #efd857;
}
.input-container input {
  padding: 10px;
  width: 100%;
  font-size: 16px;
  border: 0;
  outline: none;
  color: #333;
}
i {
  margin: 0 10px;
  color: #aaa;
  cursor: default;
}

html{
  scroll-behavior: smooth;
}

body > * {
  font-family: 'Poppins', sans-serif !important;
  color: $black;
  margin: 0;
  padding: 0;
}

body *:focus{
  outline-style: none;
}

body.admin {
  height: 100vh;
  overflow: hidden;
}

main {
  min-height: calc(100vh - 65px);
}

.main {
  margin: 80px auto;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 60px;
  &.container-s {
    max-width: 900px;
  }
}

.box-sizing-content {
  box-sizing: content-box;
}

#loader, #survey-loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

#loader {
  display: block;
  background: url('../../images/spinner.gif') center no-repeat #fff;
  background-size: 230px;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-disabled {
  cursor: not-allowed !important;
}

.hidden {
  display: none !important;
}

.display-block\@s {
  @media screen and (max-width: 640px) {
    display: block;
  }
}

.display-block\@xs {
  @media screen and (max-width: 500px) {
    display: block;
  }
}

/*****************************/



/* BUTTON */

.btnHover{
  cursor: pointer;
}

button {
  padding-inline: 0;
}

.button {
  border: none;
  //letter-spacing: 1px;
  display: inline-block;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 16px;
  &.button-s {
    font-size: 14px;
    font-weight: 600;
    color: $white;
    border-radius: 8px;
    padding: 5px 15px;
  }
  &.button-border {
    border: 1px solid;
    padding: 7px 20px;
    border-radius: 20px;
    font-size: 18px;
    font-weight: 500;
  }
  &.button-top {
    top: -15px;
  }
  &.button-m {
    padding: 0 10px;
    line-height: 30px;
  }
  &.button-l {
    padding: 10px 30px;
  }
  &.button-xl {
    padding: 0 25px;
    line-height: 50px;
  }
}


hr {
  &.hr-1 {
    border-bottom: 1px solid $grey;
  }
  &.hr-2 {
    border-bottom: 2px solid $grey;
  }
}

.thin-line {
  height: 2px;
  background-color: $alt2Grey;
  width: calc(100% - 20px);
  margin-right: 20px;
}

/*****************************/


/* BORDER */

.border-radius-5 {
  border-radius: 5px;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-radius-15 {
  border-radius: 15px;
}

.border-radius-20 {
  border-radius: 20px;
}

.border-radius-25 {
  border-radius: 25px;
}

.border-radius-30 {
  border-radius: 30px;
}

.border-radius-40 {
  border-radius: 40px;
}

.circle{
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.border {
  border-radius: 30px;
  border: 1px solid $grey;
}

.border-grey {
  border: 2px solid $grey;
}

.border-black {
  border: 1px solid $black;
}

.border-yellow {
  border: 1px solid $yellow;
}

.box-shadow {
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.1);
}

.dark-box-shadow {
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

/*****************************/


.hr-10-20 {
  margin: 10px 0 20px;
}

.list-circle {
  list-style: inside !important;
}

.list-number {
  list-style: decimal outside;
}

.flash-success {
  background-color: $green;
  color: $white;
  padding: 15px 20px;
  max-width: 800px;
  display: inline-block;
}

.card {
  border-radius: 10px;
  background-color: $white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  color: $black;
  padding: 20px;
}

.w300 {
  width: 300px;
}

.ui-datepicker {
  padding: 20px;
  box-sizing: border-box;
}

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
  top: 0;
}

.uk-nav-parent-icon > .uk-parent > a::after {
  transform: rotate(-90deg);
}

.uk-nav-parent-icon > .uk-parent.uk-open > a::after {
  transform: rotate(180deg);
}

.nav-icon-after:after {
  position: absolute;
  right: 0;
  float: none;
}

/* PICTO */

.picto-xs {
  width: 20px;
}

.picto-s {
  width: 30px;
}

.picto-m {
  width: 35px;
}

.picto-more {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  &.picto-more--small {
    width: 25px;
    height: 25px;
    line-height: 25px;
  }
}

.position-top-center {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translate(-50%, 50%);
}

.user-notif {
  width: 17px;
  height: 17px;
  line-height: 17px;
  border-radius: 50%;
  bottom: -10px;
  right: -7px;
}

.icon-info {
  height: 20px;
  overflow: initial !important;
  &.red {
    fill: $red;
  }
  &.green {
    fill: $green;
  }
}

.trash {
  background-color: transparent;
  border: none;
}

/*****************************/



/* STARS */

.star {
  height: 20px;
  margin: 5px 1px 5px 0;
}

/*****************************/



/* FILES */

.thumbnail {
  width: 75px;
  height: 75px;
  border-radius: 15px;
}

.js-btn-add-picture  {
  button {
    height: 40px;
    width: 40px;
  }
}

.js-picture-preview {
  display: none;
}

.js-picture-error {
  display: none;
}

/*****************************/


.r-kit-btn{
  text-transform: uppercase;
  font-weight: 600;
  padding: 5px 15px;
  background-color: $yellow;
  color: $black;
  border: 1px solid transparent;
  border-radius: 8px;
  font-size:16px;

  &:hover{
    cursor: pointer;
  }
}

.r-kit-btn2{
  text-transform: uppercase;
  font-weight: 600;
  padding: 5px 15px;
  background-color: white;
  color: $black;
  border: 1px solid $yellow;
  border-radius: 8px;
  font-size:16px;

  &:hover{
    cursor: pointer;
  }
}

@media screen and (max-width: 992px){
  .r-kit-btn,.r-kit-btn2{
    font-size: 15px;
  }
}

/********** ReCaptcha **************/
.grecaptcha-badge{
  display: none;
}





