
.identification {
  .form-login {
    padding: 100px;
  }
}

.password {
  .form-login {
    padding: 20px;
    box-sizing: border-box;
  }
}

.form-login {
  /*max-width: 500px;*/
  .errors{
    li{
      font-weight: bold;
    }
  }
}

.g-recaptcha div {
  margin: auto;
}

form.bgc-light-blue {
  .uk-checkbox {
    border: 1px solid $white;
  }
}

#js-modal-registration {
  display: none;
}