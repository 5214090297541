/* FLASH MESSAGES */

.flash-msg-fail, .flash-msg-success {
  border-radius: 30px;
  font-weight: 400;
}

.flash-msg-fail {
  color: $red;
  //border: 2px solid $red;
  //background-color: #f7e3de;
}

.flash-msg-success {
  color: $green;
  //border: 2px solid $green;
  //background-color: #d8fcec;
}

.flash-msg-title {
  border-radius: 5px 5px 0 0;
  display: flex;
  justify-content: center;
  padding: 20px;
  font-size: 18px;
  font-weight: 500;
}

.flash-msg-title:before {
  display: inline-block;
  transform: rotateY(160deg);
  margin: -7px 3px 0;
}

.flash-msg-title.green:before {
  content: url('../../images/identity/traits-verts.svg');
}
.flash-msg-title.red:before {
  content: url('../../images/identity/traits-rouges.svg');
}
.flash-msg-title.yellow:before {
  content: url('../../images/identity/traits-jaunes.svg');
}
.flash-msg-title.mustard:before {
  content: url('../../images/identity/traits-moutarde.svg');
}
.flash-msg-title.blue:before {
  content: url('../../images/identity/traits-bleus.svg');
}